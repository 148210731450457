import Logger from "./Logger";
const logger = new Logger("localstorage");

// Set item in localStorage
export const setLocalStorageItem = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        logger.error(`Error setting ${key} in localStorage:`, error);
    }
};

/* eslint-disable */
export const setBulkLocalStorageItem = (keyValArrayOfObjects = []) => {
    try {
        return keyValArrayOfObjects.map(({key, value}) => {
            setLocalStorageItem(key, value);
        })
    } catch (error) {
        logger.error(`Error setting in localStorage:`, error);
    }
}

// Get item from localStorage
export const getLocalStorageItem = (key) => {
    try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (error) {
        logger.error(`Error getting ${key} from localStorage:`, error);
        return null;
    }
};

export const getBulkLocalStorageItem = (keys = []) => {
    try {
        return keys.map((ele) => {
            return getLocalStorageItem(ele);
        })
} catch (error) {
    logger.error(`Error getting from localStorage:`, error);
    return null;
}

}

// Remove item from localStorage
export const removeLocalStorageItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        logger.error(`Error removing ${key} from localStorage:`, error);
    }
};

// Clear all items from localStorage
export const clearLocalStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        logger.error('Error clearing localStorage:', error);
    }
};
