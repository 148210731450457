import { React, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SettingsSvg from "../../assets/svgs/settingsSvg";
import LoadingButton from "@mui/lab/LoadingButton";
// import jwtDecode from "jwt-decode";
// import { getItem } from "../../lib/cookies";
import { decodeToken } from "../../utils/common";

const MainSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  // paddingTop: '20px'
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: 600,
  color: theme.palette.grey[800],
}));

const FormSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "calc(100% / 1.5)",
  marginTop: "13px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
  },
}));

const FormSectionRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "60px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "24px",
  },
}));

const InputSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const InputLable = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: theme.palette.grey[600],
}));

const CustomInput = styled(TextField)(({ theme }) => ({
  width: "300px",
}));

const SideImageSection = styled("div")(({ theme }) => ({
  width: "calc(100% - 100% / 1.5)",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const CustomBuuton = styled(LoadingButton)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: "23px",
  backgroundColor: theme.palette.primary.main,
  width: "170px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AccountTabPanel = () => {
  const [firstName, setFristName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  useEffect(() => {
    const {
      name = "",
      email = "",
      mobile = "",
      country = "",
      buisinessName = "",
    } = decodeToken();
    const [first, last] = name.split(" ");
    setFristName(first);
    setLastName(last);
    setEmail(email);
    setMobile(mobile);
    setCountry(country);
    setCompanyName(buisinessName);
  }, []);

  return (
    <>
      <Heading>Profile</Heading>
      <MainSection>
        <FormSection>
          <FormSectionRow>
            <InputSection>
              <InputLable>First Name</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={firstName}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>

            <InputSection>
              <InputLable>Last Name</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={lastName}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>
          </FormSectionRow>

          <FormSectionRow>
            <InputSection>
              <InputLable>Email Address</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={email}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>

            <InputSection>
              <InputLable>Phone Number</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={mobile}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>
          </FormSectionRow>

          <FormSectionRow>
            <InputSection>
              <InputLable>Country</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={country}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>

            <InputSection>
              <InputLable>Company</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={companyName}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>
          </FormSectionRow>

          <Box sx={{ pt: 1, pb: "-25px" }}>
            <Heading>Account</Heading>
          </Box>

          <FormSectionRow sx={{ mt: "-13px" }}>
            <InputSection>
              <InputLable>Username</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                value={email}
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>

            <InputSection>
              <InputLable>Password</InputLable>

              <CustomInput
                hiddenLabel
                id="filled-hidden-label-small"
                // defaultValue="Small"
                variant="outlined"
                size="small"
                inputProps={{ sx: { color: "grey.600" } }}
              />
            </InputSection>
          </FormSectionRow>

          <CustomBuuton
            variant="contained"
            // onClick={() => handleJoin()}
            // loading={loadingBtn}
            disabled
          >
            Update Setting
          </CustomBuuton>
        </FormSection>

        <SideImageSection>
          <SettingsSvg />
        </SideImageSection>
      </MainSection>
    </>
  );
};

export default AccountTabPanel;
