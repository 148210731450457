import { FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography } from "@mui/material";
import { Stack } from "@mui/system";
// import RHFRadioGroup from "../components/hook-form/rhf-radio-group";
import FormProvider from "../components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import Iconify from "../components/iconify";
import RHFTextField from "../components/hook-form/rhf-text-field";
import { LoadingButton } from "@mui/lab";
import Logger from "../lib/Logger";
import { useState } from "react";
// import { RECAPTCHA_SITE_API_KEY } from "../config/commonConfig";
import useRecaptchaV3 from "../hooks/useRecaptcha";
import { axiosPostCall } from "../services/apisCall";
import { RHFCheckbox } from "../components/hook-form/rhf-checkbox";
import { configFile } from "../config";
const { RECAPTCHA_SITE_API_KEY } = configFile;
const logger = new Logger("contactus form");


/* const CardsMainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    //   padding: '76px 12px 12px 70px',
    // height: '100%',
    //   height: window.innerHeight,
    //   overflowY: 'scroll',
    //   [theme.breakpoints.down('sm')]: {
    //     padding: '70px 14px 14px 14px',
    //     height: 'auto',
    //   },
})); */
const Contacts = () => {

    const [loading, setLoading] = useState(false);
    const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_API_KEY);
    const options = [
        { label: "Individual", icon: <Iconify icon={"material-symbols:person"} />, value: "individual" },
        { label: "Corporate", icon: <Iconify icon={"mdi:building"} />, value: "corporate" },
    ]
    const NewCampaignSchema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        email: Yup.string().email('Please enter a valid email address').required('Email is required'),
        mobile: Yup.number().required("Mobile Number is required."),
        companyName: Yup.string().required("Company Name is reuqired."),
        companySize: Yup.string().required("Company Size is required.")
    });
    const methods = useForm({
        resolver: yupResolver(NewCampaignSchema),
        defaultValues: {
            userType: "individual"
        }
    });

    const {
        // setValue,
        handleSubmit,
        control,
        watch,
    } = methods;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: (theme) => theme.palette.customGrey[100],
        borderRadius: "10px",
        boxShadow: 24,
        p: "15px 20px",
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const recaptchaToken = await executeRecaptcha("chat");
            const formData = data;
            await axiosPostCall("leads", { formData, recaptchaToken })
            setLoading(false);
        } catch (error) {
            logger.error(error);
        }
    }

    const values = watch();
    return (
        <Grid container spacing={2} sx={style}>
            <Grid item xs={12} md={8}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack width={"100%"} gap={1}>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>Online Enquiry</Typography>
                        <Stack>
                            <Stack>
                                <Typography>Looking For?</Typography>
                                <FormControl component="fieldset">
                                    <Controller
                                        name="userType"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioGroup row={true} {...field}>
                                                {options.map(({ value, icon, label }) => (
                                                    <FormControlLabel value={value} control={<Radio />} label={<Stack gap={1} direction={"row"} alignItems={"center"}>
                                                        {icon}
                                                        <Typography>{label}</Typography>
                                                    </Stack>} />
                                                ))}
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack gap={2}>
                                <RHFTextField name="name" label="Name*" size="small" />
                                <RHFTextField name="email" label="Email*" size="small" />
                                <RHFTextField name="mobile" label="Mobile Number*" size="small" />
                                {values?.userType === "corporate" && <RHFTextField name="companyName" label="Company Name*" size="small" />}
                                {values?.userType === "corporate" && <RHFTextField name="companySize" label="Company Size*" size="small" />}
                                {values?.userType === "corporate" && <RHFTextField name="companyAddress" label="Company Address*" size="small" />}
                                <RHFTextField name="requiredment" label="Requirment*"
                                    size="small"
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    fullWidth
                                />
                                <Stack gap={0}>
                                    <RHFCheckbox name="agree" label={"By submitting this form, you agree to the Privacy Policy."} />
                                    <Typography variant='body2' sx={{ color: 'grey.500', fontSize: "12px" }}>This site is protected by reCAPTCHA and the Google
                                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                    </Typography>

                                </Stack>
                                <Stack justifyContent={"left"} >
                                    <LoadingButton loading={loading} sx={{ boxShadow: "none" }} type="submit" color="primary" size="medium" variant="contained">Submit</LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </FormProvider>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack gap={2}>
                    <Typography variant="h4" sx={{ fontWeight: 500 }}>Contact Details</Typography>
                    <Stack gap={1}>
                        <Stack direction="row" gap={1} alignItems={"center"}>
                            <Iconify icon={"mdi:location"} />
                            <Typography>SiliconValley</Typography>
                        </Stack>
                        <Stack direction="row" gap={1} alignItems={"center"}>
                            <Iconify icon={"material-symbols:mail"} sx={{ width: "5px" }} />
                            <Link href="mailto:someone@example.com" underline="hover">
                                someone@example.com
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default Contacts;