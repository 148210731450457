/* eslint-disable react/jsx-no-undef */
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  FormControl,
  TextField,
  // Icon,
  InputLabel,
  Tooltip,
  Typography,
  SvgIcon,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

// import HeadingTabPanel from '../sections/settings/headingTabPanel';
import UserFilters from "../sections/users/filters";
import ListTable from "../sections/users/listTable";
import { useEffect, useState } from "react";
import { axiosGetCall, axiosPostCall } from "../services/apisCall";
import { renderErrorMessage, renderSuccessMessage } from "../lib/toastMessage";
import { checkPermission, decodeToken } from "../utils/common";
import { useNavigate } from "react-router-dom";
import CustomModal from "../components/modal/customModal";
import moment from "moment";
// import jwtDecode from "jwt-decode";
// import { getItem } from "../lib/cookies";
import AddLargeLineIcon from "../assets/svgs/icons/add-large-line";
import { useSelector } from "react-redux";
const MainSection = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  // gap: "14px",
  padding: "62px 12px 12px 72px",
  height: "100%",
  overflowY: "scroll",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: "62px 12px 12px 72px",
    height: "auto",
  },
}));

const Card = styled("div")(({ theme }) => ({
  height: "auto",
  maxHeight: "calc(100%)",
  display: "flex",
  flexDirection: "column",
  borderRadius: "6px",
  border: "1px solid",
  overflow: "hidden",
  backgroundColor: theme.palette.card.main,
  borderColor: theme.palette.grey[300],
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const HeadingSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "20px",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    flexWrap: "wrap",
  },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: "20px",
  fontWeight: 600,
}));

const TableSection = styled("div")(() => ({
  // height: 'calc(100% - 30px)'
  // padding: '0px 0px 0px 0px'
}));
const inputSx = {
  color: "grey.800",
  borderColor: "grey.700",
  borderRadius: "6px",
};
const EmployeeList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [usersList, setUsersList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [workSpaceModal, setWorkSpaceModal] = useState(false);
  const [type, setType] = useState(null);
  const [workSpaceFolderModal, setWorkSpacFoldereModal] = useState(false);
  const [count, setCount] = useState(0);
  const [employee, setEmployee] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [workSpaceFolderData, setWorkSpaceFolderData] = useState([]);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const email = decodeToken();
  // const  = getItem("workspace");

  const workspaceId = useSelector((state) => state.workspace.workspace);

  const workspaceDetails = useSelector(
    (state) => state.workspace.workspaceDetails
  );

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const [search, setSearch] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterData, setFilterData] = useState({
    email: "",
    mobile: "",
    userId: "",
    downlineType: "",
    status: null,
    type: null,
    permissions: null,
    from: null,
    to: null,
  });

  const fetchUsersList = async () => {
    try {
      setIsListLoading(true);

      const skip = rowsPerPage * page;

      const payload = {
        workspaceId,
        useremail: email?.email,
        skip,
        limit: rowsPerPage,
      };

      if (search) payload.search = search;

      const { count = 0, usersList = [] } =
        (await axiosGetCall("employeeList", payload)) || {};

      setUsersList(usersList);
      setCount(count);
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    } finally {
      setIsListLoading(false);
    }
  };

  const findForSearchQuery = (search) => {
    setSearch(search);
    if (search?.trim()) {
      setPage(0);
      setLoad(true);
      if (!filterLabels.includes("search"))
        setFilterLabels([{ key: "search", value: search }]);
      setIsFilterApplied(false);
      return;
    }
    const updatedLabels = filterLabels.filter((item) => item?.key !== "search");
    setFilterLabels([...updatedLabels]);
    setUsersList([]);
    setIsFilterApplied(false);
    setPage(0);
    setLoad(true);
  };

  useEffect(() => {
    if (load) {
      fetchUsersList();
      setLoad(false);
    }

    // eslint-disable-next-line
  }, [load]);

  const resetFilters = () => {
    // fetchUsersList()
    setFilterLabels([]);
    setSearch("");
    setFilterData({
      email: "",
      mobile: "",
      userId: "",
      downlineType: "",
      status: null,
      type: null,
      permissions: null,
      from: null,
      to: null,
    });
    setIsFilterApplied(false);
    setLoad(true);
  };

  const filterLabelMap = {
    email: "Email",
    mobile: "Mobile",
    userId: "UserId",
    status: "Status",
    type: "Type",
    permissions: "Permissions",
    from: "From",
    to: "To",
    downlineType: "DownlineType",
  };

  const generateLabelChipToolTip = (item) => {
    if (["From", "To"].includes(item?.key))
      return moment(item?.value).format("D MMM, YYYY");
    else return item?.value;
  };

  const apply = (data) => {
    setUsersList([]);
    setSearch("");
    setIsFilterApplied(true);

    const label = [];

    Object.keys(data).forEach((element) => {
      if (["permissions", "status", "type"].includes(element)) {
        data[element].length > 0 &&
          label.push({ key: filterLabelMap[element], value: data[element] });
      } else {
        data[element] &&
          label.push({ key: filterLabelMap[element], value: data[element] });
      }
    });
    setFilterLabels(label);

    const newData = JSON.parse(JSON.stringify(data));
    setFilterData(newData);
    setPage(0);
    setLoad(true);
  };
  const dropdownstyle = (
    <Box
      sx={{
        padding: "20px 0px",
      }}
    >
      <FormControl fullWidth>
        <TextField
          label="Email"
          placeholder="Please enter email"
          variant="outlined"
          value={employee}
          autoFocus
          error={employee !== "" && !validateEmail(employee)}
          helperText={
            employee !== "" && !validateEmail(employee)
              ? "Invalid email address"
              : ""
          }
          InputProps={{ sx: inputSx }}
          onChange={(e) => setEmployee(e.target.value)}
        />

        <Button
          variant="contained"
          onClick={() => {
            handleInvite();
          }}
          sx={{ mt: 2 }}
        >
          Invite
        </Button>
      </FormControl>
    </Box>
  );
  const Workspacestyle = (
    <Box
      sx={{
        padding: "20px 0px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createWorkSpace();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            error={nameError}
            helperText={nameError ? "Invalid name" : ""}
            InputProps={{
              sx: inputSx,
            }}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => setNameError(name === "")}
          />
          <TextField
            label="Description"
            variant="outlined"
            value={description}
            error={descriptionError}
            helperText={descriptionError ? "Invalid description" : ""}
            InputProps={{
              sx: inputSx,
            }}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={() => setDescriptionError(description === "")}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={nameError || descriptionError}
          >
            Create Workspace
          </Button>
        </Box>
      </form>
    </Box>
  );
  const Workspacefolderstyle = (
    <Box
      sx={{
        padding: "20px 0px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createWorkspaceFolder();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            value={folderName}
            error={folderNameError}
            helperText={folderNameError ? "Invalid name" : ""}
            InputProps={{
              sx: inputSx,
            }}
            onChange={(e) => setFolderName(e.target.value)}
            onBlur={() => setFolderNameError(folderName === "")}
          />
          <FormControl fullWidth>
            <InputLabel id="workspace-type-label">Parent</InputLabel>
            <Select
              labelId="workspace-type-label"
              id="workspace-type"
              value={type}
              label="Type"
              onChange={(e) => setType(e.target.value)}
            >
              {workSpaceFolderData?.map((item, key) => (
                <MenuItem key={key} value={item.folder_name}>
                  {item?.folder_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            disabled={nameError || descriptionError}
          >
            Create Group
          </Button>
        </Box>
      </form>
    </Box>
  );

  const createWorkspaceFolder = async () => {
    try {
      if (!folderName) return renderErrorMessage("Please enter name");
      const res = await axiosPostCall("createWorkspaceFolder", {
        folder_name: folderName,
        parent_folder_id: type,
        workspace_id: workspaceId,
      });
      if (res?.success) {
        renderSuccessMessage(res?.message);
        setWorkSpacFoldereModal(false);
        fetchUsersList();
      } else {
        renderErrorMessage(res?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      renderErrorMessage(error?.message || "Network error!");
    }
  };
  const reset = () => {
    resetFilters();
  };
  const handleInvite = async () => {
    try {
      if (!employee) return renderErrorMessage("Please enter email address");

      if (!validateEmail(employee))
        return renderErrorMessage("Invalid email address");
      const res = await axiosPostCall("inviteEmployee", {
        email: employee,
        sentMail: email?.email,
      });
      console.log(res);
      if (res?.success) {
        renderSuccessMessage(res?.message);
        setOpen(false);
        setEmployee("");
        fetchUsersList();
      } else {
        console.log(res?.data?.message);
        renderErrorMessage(res?.data?.message || "Something went wrong!");
        // renderErrorMessage("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    }
  };
  const createWorkSpace = async () => {
    console.log("name", name);
    console.log("description", description);
    try {
      if (!name) return renderErrorMessage("Please enter name");
      if (!description) return renderErrorMessage("Please enter description");
      const res = await axiosPostCall("createWorkspace", {
        type: "0",
        name,
        description,
        owner: email?.email,
      });
      console.log(res);
      if (res?.success) {
        renderSuccessMessage(res?.message);
        setWorkSpaceModal(false);
        fetchUsersList();
      } else {
        console.log(res?.data?.message);
        renderErrorMessage(res?.data?.message || "Something went wrong!");
        // renderErrorMessage("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    }
  };
  const getWorkSpaceFolder = async () => {
    if (!email?.userId || !workspaceId) return;
    try {
      const params = {
        userId: email?.userId,
        workspaceId: workspaceId,
      };
      const response = await axiosGetCall("fetchWorkspaceFolder", params);
      console.log(response);

      setWorkSpaceFolderData(response?.data);
    } catch (error) {
      console.log(error);
      renderErrorMessage(error?.message || "Network error!");
    }
  };

  useEffect(() => {
    fetchUsersList();
    getWorkSpaceFolder();

    const userPermission = checkPermission();
    if (userPermission !== "l4") navigate("/");

    //eslint-disable-next-line
  }, [workspaceId]);
  return (
    <MainSection>
      <ToastContainer />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
            disabled
          >
            Invite
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setWorkSpaceModal(true)}
            startIcon={
              <SvgIcon sx={{ width: "14px", height: "14px" }}>
                <AddLargeLineIcon color={"currentColor"} />
              </SvgIcon>
            }
          >
            Create Workspace
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setWorkSpacFoldereModal(true)}
            startIcon={
              <SvgIcon sx={{ width: "14px", height: "14px" }}>
                <AddLargeLineIcon color={"currentColor"} />
              </SvgIcon>
            }
          >
            Create Group
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/createdomain")}
            startIcon={
              <SvgIcon sx={{ width: "14px", height: "14px" }}>
                <AddLargeLineIcon color={"currentColor"} />
              </SvgIcon>
            }
          >
            Add Domain
          </Button>
        </Box>
      </Box>
      <Card>
        <HeadingSection>
          {/* <HeadingIcon disableFocusRipple disableRipple disableTouchRipple>
            <Icon icon="flowbite:users-solid" width={22} />
          </HeadingIcon> */}
          <HeadingTypography>Employee ({count})</HeadingTypography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {!!filterLabels.length && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "6px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {filterLabels.map((item) => (
                  <Tooltip title={generateLabelChipToolTip(item)}>
                    <Chip label={item?.key} variant="outlined" size="small" />
                  </Tooltip>
                ))}

                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => resetFilters()}
                >
                  Clear Filter
                </Button>
              </Box>
            )}
          </Box>

          <UserFilters
            findForSearchQuery={findForSearchQuery}
            fetchUsersList={fetchUsersList}
            type="employee"
            workspaceDetails={workspaceDetails}
            apply={apply}
            reset={reset}
            search={search}
            isFilterApplied={isFilterApplied}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </HeadingSection>

        <TableSection>
          <ListTable
            page={page}
            setPage={setPage}
            type="employee"
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setLoad={setLoad}
            usersList={usersList}
            count={count}
            isListLoading={isListLoading}
            fetchUsersList={fetchUsersList}
          />
        </TableSection>
        <CustomModal
          open={open}
          title={"Employee"}
          description={dropdownstyle}
          close={() => setOpen(false)}
        />
        <CustomModal
          open={workSpaceModal}
          title={"Create Workspace"}
          description={Workspacestyle}
          close={() => setWorkSpaceModal(false)}
        />
        <CustomModal
          open={workSpaceFolderModal}
          title={"Create Group"}
          description={Workspacefolderstyle}
          close={() => setWorkSpacFoldereModal(false)}
        />
      </Card>
    </MainSection>
  );
};

export default EmployeeList;
