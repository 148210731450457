import { useEffect } from 'react';
import parse from 'url-parse';
// import { setBulkItems } from '../lib/cookies';
import { setSecureLoginSession } from '../utils/common';

const CustomSession = () => {
  useEffect(() => {
    const url = parse(window.location.href, true);
    const { token, sessionToken, exp } = url.query;
    if (!token || !sessionToken || !exp) return ;
    setSecureLoginSession(exp, sessionToken, token, "", 0)
    // setBulkItems([
    //     { key: "exp", value: exp },
    //     { key: "sessionToken", value: sessionToken },
    //     { key: "token", value: token },
    //   ]);

    window.location.href = '/';

  }, []);
  return (<> </>)
}

export default CustomSession;

