import { Box, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react';
import {  axiosVBPatchCall, axiosVBPostCall } from '../../../services/vbApiCall';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import Logger from '../../../lib/Logger';
import { copyToClipBoard } from '../../../utils/common';
const logger = new Logger("asetting")

/* eslint-disable */
const BroadcastSettings = ({ user }) => {

  const [anouncementUploadLimit, setAnouncementUploadLimit] = useState(0);
  const [callLimit, setCallLimit] = useState(0);
  const [answerPercent, setAnswerPercent] = useState(0);
  const [callWindowRequestFor, setCallWindowRequestFor] = useState("U");
  const [callLimitRequestFor, setCallLimitRequestFor] = useState("U");
  const [answerPercentRequestFor, setAnswerPercentRequestFor] = useState("U");
  const [uploadLimitRequestFor, setUpdateLimitRequestFor] = useState("U");
  const [dndStatusRequestFor, setDndStatusRequestFor] = useState("U");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [dndStatus, setDndStatus] = useState("0");

  const [userToken, setUserToken] = useState("");
  const [callWindowloader, setCallWindowLoader] = useState(false);
  const [answerPercentLoader, setAnswerPercentLoader] = useState(false);
  const [callLimitLoader, setCallLimitLoader] = useState(false);
  const [announcementLimitLoader, setAnnouncementLoader] = useState(false);
  const [dndLoader, setDndLoader] = useState(false);


  const updateCallLimit = async () => {
    try {
      setCallLimitLoader(true);
      const payload = {
        userId: user?.userId,
        action: "updateCallLimits",
        callLimit,
        requestFor: "U",
      }
      if (user?.userType === "reseller") {
        payload.requestFor = callLimitRequestFor;
      }
      await axiosVBPatchCall("updateProfileSetting", payload);
      setCallLimitLoader(false);

      renderSuccessMessage("Success");
    } catch (error) {
      logger.error(error)
      setCallLimitLoader(false);
      renderErrorMessage(error.message);
    }
  }

  const updateAnnouncementUploadLimit = async () => {
    try {
      setAnnouncementLoader(true);
      const payload = {
        userId: user?.userId,
        action: "updateAnnouncementUploadLimit",
        announcementLimit: parseInt(anouncementUploadLimit),
        requestFor: "U",
      }
      if (user?.userType === "reseller") {
        payload.requestFor = uploadLimitRequestFor;
      }
      await axiosVBPatchCall("updateProfileSetting", payload);
      setAnnouncementLoader(false);
      renderSuccessMessage("Success");
    } catch (error) {
      logger.error(error)
      setAnnouncementLoader(false);
      renderErrorMessage(error.message)
    }
  }

  const updateDndStatus = async () => {
    try {
      setDndLoader(true);
      const payload = {
        userId: user?.userId,
        DNDFilterStatus: dndStatus,
        action: "changeUserDNDFilterStatus",
        requestFor: "U",
      };

      if (user?.userType === "reseller") {
        payload.requestFor = dndStatusRequestFor;
      }
      await axiosVBPatchCall("updateProfileSetting", payload);
      setDndLoader(false);
      renderSuccessMessage("Success");
    } catch (error) {
      logger.error(error);
      setDndLoader(false);
      renderErrorMessage(error.message);
    }
  }

  const updateACallWindow = async () => {
    try {

      if (!startTime) {
        return setStartDateError("Start date is required");
      }
      if (!endTime) {
        return setEndDateError("End date is required")
      }
      const startHour = new Date(startTime).getHours();
      const endHour = new Date(endTime).getHours();

      if (startHour > endHour) {
        return setEndDateError("End date must be greater than the Start date")
      }
      const payload = {
        userId: user?.userId,
        action: "updateCallWindow",
        callWindowStart: startHour,
        callWindowEnd: endHour,
        requestFor: "U",
      }
      if (user?.userType === "reseller") {
        payload.requestFor = callWindowRequestFor;
      }
      setCallWindowLoader(true);
      await axiosVBPatchCall("updateProfileSetting", payload);
      setCallWindowLoader(false);
      renderSuccessMessage("Success");
    }
    catch (error) {
      logger.error(error);
      setCallWindowLoader(false);
      renderErrorMessage(error.message)
    }
  }

  const updateAnsweringPercentage = async () => {
    try {
      const payload = {
        userId: user?.userId,
        answeringPercentage: answerPercent,
        action: "setAnswering",
        requestFor: "U",
      };

      if (user?.userType === "reseller") {
        payload.requestFor = answerPercentRequestFor;
      }
      await axiosVBPatchCall("updateProfileSetting", payload);
      setDndLoader(false);
      renderSuccessMessage("Success");

    } catch (error) {
      logger.error(error);
      setAnswerPercentLoader(false);
      renderErrorMessage(error.message);
    }
  }

  const handleSubmit = async (type) => {
    if (type === "callLimit") {
      await updateCallLimit();
    }

    if (type === "announcementLimit") {
      await updateAnnouncementUploadLimit();
    }

    if (type === "callWindow") {
      await updateACallWindow();
    }

    if (type === "dndStatus") {
      await updateDndStatus();
    }

    if (type === "answerPercentage") {
      await updateAnsweringPercentage();
    }
  }

  const resetStartTimeError = useMemo(() => {
    return () => {
      setStartDateError("");
    };
    // eslint-disable-next-line
  }, [startTime]);

  const resetEndTimeError = useMemo(() => {
    return () => {
      setEndDateError("")
    }
    // eslint-disable-next-line
  }, [endTime])

  const fetchSettingDetails = async () => {
    try {
      const { token = "" } = await axiosVBPostCall("tokenGenerate", { userId: user?.userId });
      setUserToken(token);

    } catch (error) {
      logger.error(error);
    }
  }

  useEffect(() => {
    fetchSettingDetails();
    // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        mt: "10px",
      }}
    >



      <Box
        sx={{
          display: "flex",
          // flexDirection: 'column',
          gap: "6px",
          mb: "15px",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          User Token :
        </Typography>
        <Tooltip title="Click here to copy">
          <Typography
            variant="body1"
            color="text.secondary"
            onClick={() => {
              copyToClipBoard(userToken);
              renderSuccessMessage("Token Copied");
            }}
          >
            {userToken}
          </Typography>
        </Tooltip>
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
                <LoadingButton
                    loading={loader}
                    variant="contained"
                    sx={{ width: "100px" }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </LoadingButton>
            </Box> */}
    </Box>
  );
}

export default BroadcastSettings