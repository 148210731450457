import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { fetchRefLoginPath } from "../../utils/common";
import { getItem } from "../../lib/cookies";

const ReferenceLogin = () => {
    const { breakpoints, /* palette: { mode } */ } = useTheme();
    if (!getItem("refSecureToken")) {
        window.location.href = "/"
    }
    const greaterThanMid = useMediaQuery(breakpoints.up("sm"));

    useEffect(() => {
        const handleMessage = (event) => {
            const eventHost = new URL(event.origin).hostname;
            if (eventHost === window.location.hostname) {
                const { logout = false } = event.data;
                if (logout) {
                    window.close();
                }
            }
        };
        window.addEventListener('message', handleMessage);
        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    console.log("hello world")
    debugger;
    return (
        <Box width={"100%"} height={"100vh"}>
            <iframe
                title="voice"
                id="voice"
                src={`${fetchRefLoginPath()}?k=console&s=true&t=${greaterThanMid ? "desktop" : "mobile"}&rf=true`}
                allow="camera *;microphone *"
                style={{
                    top: " 0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                    border: "0px",
                }}
            />
        </Box>
    )
}

export default ReferenceLogin;