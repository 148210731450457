import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage.json";
import { getBulkItems, getItem, removeItem, setBulkItems, setItem } from "../lib/cookies";
import { configFile, currentProduct } from "../config";
import { CUSTOM_ROUTE, PLATFORM_MAPPER } from "../config/commonConfig";
import { axiosPostCall } from "../services/apisCall";
import jwtDecode from 'jwt-decode';
import Logger from "../lib/Logger";
import moment from 'moment'
import parse from 'url-parse';
// import { result } from "lodash";
const { URL_MAPPER } = configFile;
const logger = new Logger("Common");
const product = currentProduct();

const { allowedDomain, blockedDomain, allowedFeatures, whiteListPlatForm } =
  await import(`../config/${product}/branding`);

//For handle apis error response message.
const prepareMessageFromError = (error) => {
  return (
    error?.response?.data?.message || error?.message || ErrorMessage?.[4000]
  );
};

//Define prop type of fn.
prepareMessageFromError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // pass complete error or custom message.
};

// Validate email.
const validateEmail = (email) => {
  // eslint-disable-next-line
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

validateEmail.propTypes = {
  email: PropTypes.string.isRequired,
};

// Validate userId
const validateUserId = (userId) => {
  return /^[0-9]{6,8}$/.test(userId);
};

validateUserId.propTypes = {
  userId: PropTypes.number.isRequired,
};

// validate password
const validatePassword = (password) => {
  // eslint-disable-next-line
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,20}$/.test(
    password
  );
};

// validate Domain
const validateDomain = (email) => {
  const domain = email.split("@")[1];

  if (blockedDomain.includes(domain)) return false;
  if (allowedDomain.includes("*")) return true;
  return allowedDomain.includes(domain);
};

validatePassword.PropTypes = {
  password: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// Set user session.
const setLoginSession = (exp, sessionToken, token, workspace, accountId) => {
  if (accountId === 0) {
    return setBulkItems([
      { key: "exp", value: exp },
      { key: `sessionToken`, value: sessionToken },
      { key: `token`, value: token },
      { key: "workspace", value: workspace },
    ]);
  }
};

const setSecureLoginSession = (exp, sessionToken, token, workspace, accountId) => {
  const secureToken = getItem("secureToken");
  const localToken = localStorage.getItem("token") || [];
  if (secureToken) {
    const parsedToken = JSON.parse(secureToken);
    const parsedLocalToken = JSON.parse(localToken);
    const newAccountToken = {
      sessionToken,
      exp,
      workspace,
      accountId,
    }
    const accountIdAlreadyExists = parsedToken.findIndex((token) => token?.accountId === accountId);
    if (accountIdAlreadyExists !== -1) {
      parsedToken.splice(accountIdAlreadyExists, 1, newAccountToken);
      parsedLocalToken.splice(accountIdAlreadyExists, 1, token)
    } else {
      parsedToken.push(newAccountToken);
      parsedLocalToken.push(token);
    }
    setItem("secureToken", JSON.stringify(parsedToken))
    localStorage.setItem("token", JSON.stringify(parsedLocalToken))
  } else {
    const newAccountToken = {
      sessionToken,
      exp,
      workspace,
      accountId,
    }
    setItem("secureToken", JSON.stringify([newAccountToken]))
    localStorage.setItem("token", JSON.stringify([token]))
  }
}

setLoginSession.propTypes = {
  exp: PropTypes.number.isRequired,
  sessionToken: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

// Get user session.
const getLoginSession = () => {
  // const currentPath = window.location.pathname.split("/");
  let cookiesNameArray = ["exp", "sessionToken", "token"];
  // if(currentPath && currentPath.length >3) {
  //   const current = window.location.pathname.split("/")[2];
  // cookiesNameArray = ["exp", `sessionToken-${current}`, `token-${current}`];
  // } else {
  //   cookiesNameArray = 
  // }
  return getBulkItems(cookiesNameArray);
};

const getSecureLoginSession = () => {
  const secureToken = getItem("secureToken");
  if (secureToken) {
    const parsedToken = JSON.parse(secureToken);
    const currentPath = window.location.pathname.split("/");
    if (currentPath && currentPath.length >= 3) {
      const result = parsedToken[currentPath[2]];
      return result || {};
    } else {
      const result = parsedToken[0];
      return result || {};
    }
  } else {
    return {};
  }


}

const getAllDecodedSecureToken = () => {
  if(checkIframe()){
    const secureToken = getItem("refSecureToken");
    if (secureToken) {
      const parsedToken = JSON.parse(secureToken) || [];
      return parsedToken.map(({ token, accountId }) => {
        return { ...jwtDecode(token), accountId };
      })
    }
    return [];
  }
  const secureToken = localStorage.getItem("token");
  if (secureToken) {
    const parsedToken = JSON.parse(secureToken) || [];
    return parsedToken.map((token) => {
      return { ...jwtDecode(token) };
    })
  }
  return [];
}

const fetchRoutePath = (path, customRoute) => {
  if (!customRoute) return `${URL_MAPPER[path]}`;
  return `${URL_MAPPER[path]}/${customRoute ? customRoute : CUSTOM_ROUTE}`;
};

const getCurrentAccountId = () => {
  const currentPath = window.location.pathname.split("/");
  if (currentPath && currentPath.length >= 3) {
    return parseInt(currentPath[2]);
  } else {
    return 0;
  }
}

//Redirecting path
const handleRedirect = async (
  path,
  customRoute = "custom_session",
  session = {},
  self = false,
  mode = "",
  j = false,
  roomId = "",
  route,
) => {
  try {
    // TODO :- Remove this check if all path available

    // eslint-disable-next-line
    const [{ sessionToken: sesToken }, { token: consoleToken }, { scheduleTime }] = getBulkItems(["sessionToken", "token", "scheduleTime", "secureToken"]);


    if (!sesToken) {
      return Object.keys(PLATFORM_MAPPER).forEach((key) => {
        if (PLATFORM_MAPPER[key] === path) {
          if (self) return (window.location.href = "/");
          return window.parent.postMessage(
            { redirectPath: `${URL_MAPPER["console"]}/login?k=${key}` },
            "*"
          );
        }
      });
    }
    if (URL_MAPPER[path] === "") return;

    // path = whiteListPlatFormChecker(path);

    //handling upcoming product
    if (!sesToken || ["/", "deepCall", ""].includes(path)) {
      if (self) {
        if (["deepCall"].includes(path))
          return (window.location.href = `/${path}`);
        return (window.location.href = "/");
      }
      if (path === "/" || path === "")
        return window.parent.postMessage(
          { redirectPath: `${URL_MAPPER["console"]}${getCurrentPath()}` },
          "*"
        );
      window.parent.postMessage(
        { redirectPath: `${URL_MAPPER["console"]}/${path}${getCurrentPath()}` },
        "*"
      );
      return;
    }

    const {
      sessionToken: sToken = "",
      exp: expiry = "",
      token: t = "",
    } = session;
    let st = {};
    if (sToken && expiry && t) {
      st["session-token"] = sToken;
    }

    const payload = {
      key: path,
      token: decodeToken(),
    }

    const response = await axiosPostCall("sessionSharing", payload, true, st);

    if (response?.status === 205) {
      const products = checkProductAccess() || [];
      let path = "";
      if (products?.length && !products?.includes('Console')) path = products?.[0]?.toLowerCase()
      if (path) {
        const [{ sessionToken }, { token }, { exp }] = getBulkItems(["sessionToken", "token", "exp", "secureToken"]);
        handleRedirect(path, "", { sessionToken, exp, token }, true, mode);
        return ""
      }
      else {
        window.location.href = "/login";
        return;
      }
    }

    const { exp, sessionToken, token, reload = false } = response?.data;



    if (reload) {

      if (!checkIframe()) {

        setItem("token", token);
      }
      else {
        setItem("consoleRef", JSON.stringify({ sessionToken, exp, token }))
      }
    }

    if (exp && sessionToken && token) {
      if (process.env.REACT_APP_SINGLE_DOMAIN === "true") {
        const urlPath = fetchRoutePath(path, "");
        if (self) {
          if (roomId) {
            if (path === "meet") return (window.location.href = `${urlPath}${getCurrentPath()}/join_meet?roomId=${roomId}`);
            if (path === "webinar") return (window.location.href = `${urlPath}${getCurrentPath()}/join_webinar?roomId=${roomId}`);
          }
          // return window.parent.postMessage({ redirectPath: url }, "*");
          return (window.location.href = `${urlPath}${getCurrentPath()}${route?route:""}`);
        }
        else {

          return window.parent.postMessage({ redirectPath: urlPath }, "*");
        }

      }
      const urlPath = fetchRoutePath(path, customRoute);

      const secureToken = getItem("secureToken")
      const encodedSecureToken = encodeURIComponent(JSON.stringify(secureToken));

      const url = `${urlPath}?s=${sessionToken}&e=${exp}&t=${token}&m=${mode}&j=${j}&r=${roomId}&st=${scheduleTime}?sec=${encodedSecureToken}`;

      if (self) return (window.location.href = url);
      return window.parent.postMessage({ redirectPath: url }, "*");
    }
  } catch (error) {
    logger.error("common [handleRedirect] catchError", error);
  }
};

const whiteListPlatFormChecker = (path) => {
  if (whiteListPlatForm.includes(path)) return path;
  if (whiteListPlatForm.length === 1) return whiteListPlatForm[0];
  return "";
};

const allowedRoute = (route) => {
  return allowedFeatures?.[route];
};

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function daysDifference(date1, date2) {
  const dateObject1 = new Date(date1 * 1000);
  const dateObject2 = new Date(date2 * 1000);

  if (isNaN(dateObject1.getTime()) || isNaN(dateObject2.getTime())) {
    return "Invalid date input";
  }

  const year1 = dateObject1.getFullYear();
  const year2 = dateObject2.getFullYear();
  let daysInYear1 = 365;
  let daysInYear2 = 365;

  if (isLeapYear(year1)) {
    daysInYear1++;
  }
  if (isLeapYear(year2)) {
    daysInYear2++;
  }

  const timeDifference =
    dateObject2.getTime() -
    dateObject1.getTime() +
    (daysInYear2 - daysInYear1) * (24 * 60 * 60 * 1000);

  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return dayDifference;
}

const dayAndMontBetweenDate = (dayDiff, startTimestamp, endTimestamp) => {
  let days = "";
  if (startTimestamp && endTimestamp) {
    days = daysDifference(startTimestamp, endTimestamp);
  }
  // const days = daysDifference(startTimestamp, endTimestamp);
  const today = new Date();
  const lastDays = [];
  for (let i = dayDiff - 1 || days; i >= 0; i--) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);

    const day = currentDate.getDate();
    const month = currentDate.toLocaleString("default", { month: "short" });

    lastDays.push(`${day} ${month}`);
  }
  return lastDays;
};

// formate name like first letter upper case.
const nameFormater = (value) => {
  return value && value[0].toUpperCase() + value.slice(1).toLowerCase();
}

// Formate data and time.
const dateFormate = (value, formate) => {
  const date = moment(value).format('D MMMM')
  const time = moment(value).format('h:mm a')
  return { date, time }
}

// copy text to clip board.
const copyToClipBoard = (text) => {
  navigator.clipboard.writeText(text);
}

const checkPermission = (isAll = false) => {
  try {
    const token = decodeToken();
    if (token) {
      const info = token;
      // console.log(info);

      return isAll ? info : info?.permission
    }

    return false
  } catch (error) {
    console.log(error)
    return false
  }
}

const fetchRefLoginPath = () => {
  if (window.location.hostname === "localhost") {
    return `http://localhost:3000`
  }
  return `https://${window.location.hostname}`
}

const checkIframe = () => {
  const url = parse(window.location.href, true);
  const { spinner } = url.query;

  if (window.frameElement && !spinner) {
    return true;
  }
  return false;
}


// For random password purpose
function generateRandomString() {
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|?';

  const allChars = lowercase + uppercase + digits + specialChars;
  const length = Math.floor(Math.random() * 13) + 8; // Length between 8 and 20

  let result = '';

  // Ensure the result has at least one of each required character
  result += lowercase[Math.floor(Math.random() * lowercase.length)];
  result += uppercase[Math.floor(Math.random() * uppercase.length)];
  result += digits[Math.floor(Math.random() * digits.length)];
  result += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the rest of the string with random characters
  for (let i = result.length; i < length; i++) {
    result += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the result to ensure randomness
  result = result.split('').sort(() => 0.5 - Math.random()).join('');

  return result;
}

const compareObjectValues = (prev, updated) => {

  for (let [key] of Object.entries(updated)) {
    if (updated?.[key] && prev[key] !== updated[key]) {
      return true;
    }
  }
  return false;
}

const checkProductAccess = () => {
  const data = checkPermission(true);
  if (!data) return

  const { products = [] } = data;

  if (!products?.length) {
    removeItem('token')
    return window.location.href = "/noProducts"
  }
  return products;
}

const getProductList = () => {
  try {
    const token = decodeToken();
    if (token) {
      const { products = [] } = token
      return products

    }
    return []

  } catch (error) {
    console.log(error)
    return false
  }
}

const getProductPermissions = () => {
  try {
    const token = decodeToken();
    if (token) {
      const { productPermissions = [] } = token;
      return productPermissions

    }
    return []

  } catch (error) {
    console.log(error)
    return [];
  }
}

const getforceVerifyPemission = () => {
  try {
    const token = decodeToken();
    if (token) {
      const { allowToForceVerify = false } = token;
      return allowToForceVerify

    }
    return false
  } catch (error) {
    console.log(error)
    return false
  }
}

const isVoiceReseller = () => {
  const jwtToken = decodeToken();
  return jwtToken?.vb?.userType === "reseller"
}

const isMainUser = () => {

  // const consoleToken = getItem("token")

  const { email } = decodeToken();

  return email === "rc@sarv.com";
}


function formatDate(dateString) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  const offsetIST = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
  const dateIST = new Date(date.getTime() + offsetIST);

  // Extract hours and minutes
  let hours = dateIST.getUTCHours();
  const minutes = dateIST.getUTCMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, set it to 12

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // // Extract date (in IST)
  // const year = dateIST.getUTCFullYear();
  // const month = dateIST.getUTCMonth() + 1; // Months are 0-indexed
  // const day = dateIST.getUTCDate();

  // Return formatted date
  return `${hours}:${formattedMinutes} ${ampm}`;
}

const addGST = (total, gstper) => {
  return (total * Number(1 + (gstper / 100)).toFixed(2))
}

function parseMXRecord(mxRecord) {
  const parts = mxRecord.split(" ");

  // Get subdomain part by splitting at the first dot if it exists
  const domainParts = parts[0].split(".");
  const host = domainParts.length > 3 ? domainParts[0] : "@";

  // Convert TTL from seconds to the nearest minute, hour, or day
  const ttlSeconds = parseInt(parts[1]);
  let ttl;
  if (ttlSeconds >= 86400) {
    ttl = `${Math.round(ttlSeconds / 86400)} day(s)`;
  } else if (ttlSeconds >= 3600) {
    ttl = `${Math.round(ttlSeconds / 3600)} hour(s)`;
  } else if (ttlSeconds >= 60) {
    ttl = `${Math.round(ttlSeconds / 60)} minute(s)`;
  } else {
    ttl = `${ttlSeconds} second(s)`;
  }

  const priority = parseInt(parts[4]);
  const pointsTo = parts[5];

  return {
    host,
    ttl,
    priority,
    pointsTo,
  };
}

function parseSPFRecord(mxRecord) {
  const parts = mxRecord.split(" ");

  // Get subdomain part by splitting at the first dot if it exists
  const domainParts = parts[0].split(".");
  const host = domainParts.length > 3 ? domainParts[0] : "@";

  // Convert TTL from seconds to the nearest minute, hour, or day
  const ttlSeconds = parseInt(parts[1]);
  let ttl;
  if (ttlSeconds >= 86400) {
    ttl = `${Math.round(ttlSeconds / 86400)} day(s)`;
  } else if (ttlSeconds >= 3600) {
    ttl = `${Math.round(ttlSeconds / 3600)} hour(s)`;
  } else if (ttlSeconds >= 60) {
    ttl = `${Math.round(ttlSeconds / 60)} minute(s)`;
  } else {
    ttl = `${ttlSeconds} second(s)`;
  }

  const contentMatch = mxRecord.match(/TXT\s+((?:".+?"\s*)+)/);
  let content = contentMatch[1];

  return {
    host,
    ttl,
    content,
  };
}

function parseDKIMRecord(dkimRecord, type) {
  const parts = dkimRecord.split(" ");

  // Extract the DKIM key name, adjusting for subdomain if necessary
  const domainParts = parts[0].split(".");

  let name = "";
  if (type === "dkim") {
    name = domainParts.length > 5
      ? `${domainParts[0]}.${domainParts[1]}.${domainParts[2]}`
      : `${domainParts[0]}.${domainParts[1]}`;
  }
  else if (type === "dmarc") {
    name = domainParts.length > 4
      ? `${domainParts[0]}.${domainParts[1]}`
      : domainParts[0];
  }


  // Convert TTL from seconds to nearest minute, hour, or day
  const ttlSeconds = parseInt(parts[1]);
  let ttl;
  if (ttlSeconds >= 86400) {
    ttl = `${Math.round(ttlSeconds / 86400)} day(s)`;
  } else if (ttlSeconds >= 3600) {
    ttl = `${Math.round(ttlSeconds / 3600)} hour(s)`;
  } else if (ttlSeconds >= 60) {
    ttl = `${Math.round(ttlSeconds / 60)} minute(s)`;
  } else {
    ttl = `${ttlSeconds} second(s)`;
  }

  // Extract and combine the entire DKIM key content after the "TXT" field
  const contentMatch = dkimRecord.match(/TXT\s+((?:".+?"\s*)+)/);
  const content = contentMatch[1];

  return {
    name,
    ttl,
    content,
  };
}

function formatBytes(bytes) {
  if (bytes < 1024) return `${bytes} B`; // Less than 1 KB
  const units = ["KB", "MB", "GB", "TB"];
  let i = -1;
  do {
    bytes = bytes / 1024;
    i++;
  } while (bytes >= 1024 && i < units.length - 1);

  return `${bytes.toFixed(2)} ${units[i]}`;
}

// const decodeToken = () => {
//   const token = getItem("token");
//   try {
//     const result = jwtDecode(token);
//     return result;
//   } catch (error) {
//     console.error("Invalid token:", error.message);
//   }
// }


const decodeToken = () => {
  const token = getItem("token");
  if (token) {
    try {
      const jwtToken = jwtDecode(token);
      return jwtToken;
      // Proceed with using the decoded token
    } catch (error) {
      console.error("Invalid token:", error.message);
      // Handle the error (e.g., redirect to login, show an error message)
    }
  } else {
    console.error("No token found in cookies");
    // return window.location.href = createRedirectPath("/login")
    // createRedirectPath
    // Handle the absence of a token (e.g., redirect to login)
  }
}

const getCurrentSessionToken = () => {
  const sessionToken = getItem("sessionToken");
  return sessionToken;
}

const getCurrentPath = () => {
  const currentPath = window.location.pathname.split("/");
  if (currentPath && currentPath.length >= 3) {
    return `/u/${currentPath[2]}`;
  } else {
    return "/u/0";
  }
}

function isValidDomain(domain) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)*([a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,})$/;
  return domainRegex.test(domain);
}

export {
  prepareMessageFromError,
  validateEmail,
  validateUserId,
  validatePassword,
  setLoginSession,
  getLoginSession,
  handleRedirect,
  whiteListPlatFormChecker,
  validateDomain,
  allowedRoute,
  dayAndMontBetweenDate,
  nameFormater,
  dateFormate,
  copyToClipBoard,
  checkPermission,
  fetchRefLoginPath,
  checkIframe,
  generateRandomString,
  compareObjectValues,
  checkProductAccess,
  getProductList,
  isVoiceReseller,
  getforceVerifyPemission,
  isMainUser,
  getProductPermissions,
  formatDate,
  addGST,
  formatBytes,
  parseMXRecord,
  parseDKIMRecord,
  parseSPFRecord,
  isValidDomain,
  decodeToken,
  getCurrentSessionToken,
  getCurrentPath,
  setSecureLoginSession,
  getSecureLoginSession,
  getAllDecodedSecureToken,
  getCurrentAccountId
};
