// WorkspaceSteps.js
import React, { useEffect, useState } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import SuccessPage from './successPage';
import ExistingDomainForm from './existingDomainForm.jsx';
import DomainVerification from './DomainVerification.jsx';
import ChooseDomainType from './chooseDomainType';
import MXRecordsVerification from './MXRecordsVerification';
import Logger from '../../../lib/Logger';
// import { getItem } from '../../../lib/cookies';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import EmailSetUp from './emailSetUp.jsx';
import { useBoolean } from '../../../hooks/use-boolean.js';
import Scrollbar from '../../../components/scrollbar/scrollbar.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkspace } from '../../../redux/action/workspaceAction.js';

const logger = new Logger("workspaceSteps");

const steps = [
    'Choose Domain Type',
    'Domain Ownership Verification',
    'Configure MX and A Records',
    'Set Up Email Accounts',
    'Complete Workspace Setup'
];

const WorkspaceSteps = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    // const [workspaceDetails, setWorkspaceDetails] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [domainName, setDomainName] = useState("");
    const [isDomainRegistered, setIsDomainRegistered] = useState(false);
    const [workspaceName, setWorkspaceName] = useState("");
    const fetchProgress = useBoolean(false);
    const dispatch = useDispatch();

    const workspaceId = useSelector((state) => state.workspace.workspace);

    const workspaceDetails = useSelector(
        (state) => state.workspace.workspaceDetails
    );

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedOption(null);
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
    };

    const handleAddDomain = async (domainName) => {
        try {
            setDomainName(domainName)
            const payload = {
                workspaceName,
                domainName
            }
           /*  const { message } = */ await axiosPostCall("addDomain", payload);
            // renderSuccessMessage(message)
            await fetchWorkSpaceDetails();

        } catch (error) {
            logger.error("WorkspaceSteps fn: [handleAddDomain] error:", error)
            return renderErrorMessage(error?.message)
        }
    }

    const generateVerificationTxt = async () => {
        try {
            const { message } = await axiosPostCall("generateVerificationTXT", { domainName });
            renderSuccessMessage(message);
            await fetchWorkSpaceDetails();
        } catch (error) {
            logger.error("WorkspaceSteps fn: [generateVerificationTxt] error:", error)
            return renderErrorMessage(error?.message)
        }
    }

    const verifyTxtRecord = async () => {
        try {

            const { message } = await axiosGetCall("txtRecordVerificaion", { domainName });
            renderSuccessMessage(message);
            await fetchWorkSpaceDetails();

        } catch (error) {
            logger.error("WorkspaceSteps fn: [verifyTxtRecord] error:", error)
            return renderErrorMessage(error?.message)
        }
    }

    const generatedkimRecords = async () => {
        try {
            const { message } = await axiosPostCall("generatedkimRecords", { domainName })
            renderSuccessMessage(message)
            await fetchWorkSpaceDetails();

        } catch (error) {
            logger.error("WorkspaceSteps fn: [generatedkimRecords] error:", error)
            return renderErrorMessage(error?.message)

        }
    }

    const verifyMXDkimRecords = async () => {
        try {

            const { message } = await axiosGetCall("verifyMXDkimRecords", { domainName })
            renderSuccessMessage(message);
            await fetchWorkSpaceDetails();
        } catch (error) {
            logger.error("WorkspaceSteps fn: [verifyMXDkimRecords] error:", error)
            renderErrorMessage(error?.message)

        }
    }

    const fetchWorkSpaceProgress = async () => {
        try {
            const { isDomainRegistered } = workspaceDetails;

            if (workspaceId) {
                setWorkspaceName(workspaceId)

                if (!isDomainRegistered) {
                    setActiveStep(0);
                }
                else {
                    // setWorkspaceDetails(data);
                    setIsDomainRegistered(true);
                    setSelectedOption("existing");
                    const { currentStep, domain } = workspaceDetails;
                    setDomainName(domain);

                    switch (currentStep) {
                        case "Domain Ownership Verification":
                            setActiveStep(1);
                            setCurrentStep(1)
                            break;

                        case "Configure MX and Other Records":
                            setActiveStep(2);
                            setCurrentStep(2)
                            break

                        case "Set Up Email Accounts":
                            setActiveStep(3);
                            setCurrentStep(3)
                            break

                        case "Complete Workspace Setup":
                            setActiveStep(4);
                            setCurrentStep(4)
                            break

                        default:
                            setActiveStep(0);
                            break;
                    }
                }
            }
            fetchProgress.onTrue();
        } catch (error) {
            logger.error("WorkspaceSteps fn: [fetchWorkSpaceProgress] error:", error)
        }
    }

    const fetchWorkSpaceDetails = async () => {
        try {
            if (workspaceName) {
                const { data = {}, isDomainRegistered } = await axiosGetCall(
                    "workspaceProgress",
                    { workspaceName: workspaceName }
                );
                dispatch(
                    updateWorkspace({
                        workspace: workspaceName,
                        workspaceDetails: { ...data, isDomainRegistered },
                    })
                );
            }
        } catch (error) {
            console.error(
                "WorkspaceSteps fn: [fetchWorkSpaceProgress] api:[consoleDetails] get catchError",
                error
            );
        }
    };

    const setUpEmailServer = async () => {
        try {

            const { message } = await axiosPostCall("setupEmailServer", { domainName })
            renderSuccessMessage(message);
            await fetchWorkSpaceDetails();

        } catch (error) {
            logger.error("WorkspaceSteps fn: [setUpEmailServer] api:[consoleDetails] get catchError", error);
            return renderErrorMessage(error?.message)
        }
    }

    useEffect(() => {
        fetchWorkSpaceProgress();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceDetails])

    return (
        <Scrollbar sx={{ width: '100%', padding: "76px 82px 12px 140px", height: '100vh' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {fetchProgress.value ? <>
                <Box sx={{ mt: 6 }}>
                    {activeStep === steps.length ? (
                        <SuccessPage /> // Render the SuccessPage when all steps are completed
                    ) : (
                        <Box>
                            <Typography sx={{ mt: 2, mb: 1, color: 'grey.700' }}>
                                {`Step ${activeStep + 1}: ${steps[activeStep]}`}
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                {activeStep === 0 ? (
                                    selectedOption === 'existing' ? (
                                        <ExistingDomainForm onNext={handleNext} handleAddDomain={handleAddDomain} isDomainRegistered={isDomainRegistered} domainName={domainName} setDomainName={setDomainName} />
                                    ) : (
                                        <ChooseDomainType onSelect={handleSelect} />
                                    )
                                ) : activeStep === 1 ? (
                                    <DomainVerification onProtect={handleNext} onAddUsers={handleNext} data={workspaceDetails?.steps?.[1]} generateVerificationTxt={generateVerificationTxt} verifyTxtRecord={verifyTxtRecord} />
                                ) : activeStep === 2 ? (
                                    <MXRecordsVerification data={workspaceDetails?.steps?.[2]} setUpEmailServer={setUpEmailServer} generatedkimRecords={generatedkimRecords} verifyMXDkimRecords={verifyMXDkimRecords} /> // Render MXRecordsVerification for step 3
                                ) : activeStep === 3 ? (
                                    <EmailSetUp data={workspaceDetails?.steps?.[3]} />
                                ) : (
                                    <Typography>Step Content Here</Typography> // Placeholder for the fourth step

                                )

                                }
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                    <Button
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        variant='outlined'
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
                        disabled={activeStep === currentStep}
                        variant='outlined'
                        color="primary"
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </> :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            }
        </Scrollbar>
    );
};

export default WorkspaceSteps;
