import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, ListItemButton as Item, Box, Typography, SvgIcon, ListItemIcon, Avatar, ListItemText, Button } from '@mui/material';
import StarLineIcon from '../../assets/svgs/icons/star-line';
import MailIcon from '../../assets/svgs/productIcons/mail';
import { formatDate, handleRedirect } from '../../utils/common';
import Scrollbar from '../../components/scrollbar'
import { formatDistanceToNowStrict } from 'date-fns';
import StarFillIcon from '../../assets/svgs/icons/star-fill';
import ChatIcon from '../../assets/svgs/productIcons/chat';
import { Stack } from '@mui/system';
import CalendarEventFillIcon from '../../assets/svgs/icons/fill/calendar-event-fill';
import Logger from "../../lib/Logger";
import LinearLoader from '../../components/Loader/LinearLoader';
import { useSnackbar } from '../../components/snackbar';

const logger = new Logger("MailNChatSection");

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))

const MailNChatSection = ({ chats, mails, mailLoading, mailProgress, chatLoading, chatProgress, products, handleHighlight }) => {
    const { byId = {}, ids = [] } = mails;
    const { enqueueSnackbar } = useSnackbar();
    const handleRedirectToChat = async (id) => {
        try {
            if (!products.includes("Chat")) {
                handleHighlight();
                return enqueueSnackbar("You currently do not have access to the Chat product. Please reach out to your Account Manager for assistance.", {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: "error"
                });
            }
            if(id) {
                await handleRedirect("chat", "", {}, true, "", false, "", `?id=${id}` )
            } else {
                await handleRedirect("chat", "", {}, true, "");
            }
        } catch (error) {
            logger.error("upcomingMeetSection fn: [handleJoin] api:[shareSession] post catchError", error);

        }
    }

    const handleRedirectToMail = async () => {
        try {

            if (!products.includes("Email")) {
                handleHighlight();
                return enqueueSnackbar("You currently do not have access to the Email product. Please reach out to your Account Manager for assistance.", {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: "error"
                });

            }
            handleRedirect("email", "", {}, true, "")

        } catch (error) {
            logger.error("MailandChatSection fn: [viewAllEmailsClick] api:[shareSession] post catchError", error);

        }
    }

    return (
        <Grid container spacing={2} sx={{
            minHeight: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
            height: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
            paddingLeft: '0px',
            paddingRight: '0px'
        }}>
            <Grid item sm={12} md={8} xl={8} xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon sx={{
                                width: '25px',
                                height: '28px',
                                color: 'primary.dark'
                            }}>
                                <MailIcon color={'currentColor'} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: '16px',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Email
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Track your recent emails
                                </Typography>
                            </Box>

                        </Box>

                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: 'primary.dark',
                                cursor: 'pointer',
                            }}
                            onClick={handleRedirectToMail}
                        >

                            View all
                        </Typography>

                    </Box>
                    {mailLoading.value && <LinearLoader progress={mailProgress} />}

                    {
                        !mailLoading.value &&
                        <>


                            {ids.length > 0 ?
                                <Scrollbar sx={{ height: 295, }}>

                                    {ids.map((key) => (
                                        <ListItem key={key} sx={{ maxHeight: '40px' }}>
                                            <ListItemIcon sx={{ minWidth: '60px' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '12px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>

                                                    <Avatar
                                                        src='./dom'
                                                        sx={{
                                                            width: '22px',
                                                            height: '22px',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            color: 'grey.1000'
                                                        }} >
                                                        {byId[key]?.from?.name[0]?.toUpperCase() || byId[key]?.from?.address[0]?.toUpperCase() || "U"}
                                                    </Avatar>

                                                    {byId[key]?.attributes?.flags.includes("\\Flagged")
                                                        ?
                                                        <SvgIcon sx={{
                                                            width: '14px',
                                                            height: "14px",
                                                            color: 'warning.lighter'
                                                        }}>
                                                            <StarFillIcon color={'currentColor'} />
                                                        </SvgIcon>
                                                        :
                                                        <SvgIcon sx={{
                                                            width: '14px',
                                                            height: "14px"
                                                        }}>
                                                            <StarLineIcon color={'currentColor'} />
                                                        </SvgIcon>
                                                    }

                                                </Box>

                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '12px',
                                                        // justifyContent: 'center',
                                                        alignItems: 'center',
                                                        overflow: 'hidden'
                                                    }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                color: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 'grey.700' : 'grey.1000',
                                                                minWidth: '180px'
                                                            }}>

                                                            {console.log("nitesh--->", byId[key])}
                                                            {byId[key]?.cc?.value?.length ? ((byId[key]?.from?.name?.split(" ")[0] || byId[key]?.from?.address?.split("@")[0])) + " " + byId[key]?.cc?.value.map(item => (item?.name?.split(" ")[0] || item?.address?.split("@")[0])) : (byId[key]?.from?.name || byId[key]?.from?.address?.split("@")[0])}
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                // color: 'grey.1000',
                                                                color: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 'grey.700' : 'grey.1000',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                flexDirection: 'row',
                                                                // minWidth: '180px',
                                                                width: '100%',
                                                                // flexGrow: 1
                                                            }}>
                                                            {byId[key]?.subject || "(no subject)"}
                                                            <Box component={'spam'} sx={{
                                                                pl: '5px',
                                                                color: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 'grey.500' : 'grey.600',
                                                            }}>
                                                                <Typography sx={{
                                                                    // color: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 'grey.1100' : 'text.primary',
                                                                    fontSize: '14px',
                                                                    fontWeight: (byId[key]?.attributes?.flags.includes("\\Seen")) ? 300 : 400,
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    width: '100%',
                                                                    // width: "700px"
                                                                }}>
                                                                    {"-"} {byId[key]?.text}
                                                                </Typography>
                                                            </Box>
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                            <Box sx={{
                                                display: 'flex',
                                                paddingRight: '12px',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end'
                                            }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        color: 'grey.600',
                                                        minWidth: '65px',
                                                        textAlign: 'end'
                                                    }}>
                                                    {byId[key]?.date && formatDistanceToNowStrict(new Date(byId[key]?.date), {
                                                        addSuffix: false,
                                                    })}
                                                </Typography>

                                            </Box>
                                        </ListItem>

                                    ))}
                                </Scrollbar>
                                :
                                <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: 'grey.500',
                                            maxWidth: '360px',
                                            textAlign: 'center'
                                        }}>
                                        No Email scheduled yet. Create Your first email.
                                    </Typography>
                                    <Button
                                        sx={{
                                            fontSize: '12px',
                                            color: 'primary.light',
                                            fontWeight: 600,
                                            backgroundColor: 'primary.200',
                                            mt: '8px',
                                            padding: '8px !important'
                                        }}
                                        onClick={handleRedirectToMail}
                                        startIcon={
                                            <SvgIcon sx={{
                                                width: '16px',
                                                height: '16px',
                                                ml: '2px'
                                            }}>
                                                <CalendarEventFillIcon color={"currentColor"} />
                                            </SvgIcon>
                                        }
                                    >

                                        Compose Email
                                    </Button>
                                </Stack>
                            }
                        </>
                    }
                </Box>
            </Grid>

            <Grid item sm={12} md={4} xl={4} xs={12}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <SvgIcon sx={{
                                width: '25px',
                                height: '28px',
                                color: 'primary.dark'
                            }}>
                                <ChatIcon color={'currentColor'} />
                            </SvgIcon>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: '16px',
                                alignItems: 'flex-start'
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: 'grey.800'
                                    }}>
                                    Chat
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: 'grey.600'
                                    }}>
                                    Recent messages
                                </Typography>
                            </Box>

                        </Box>

                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: 'primary.dark',
                                cursor: 'pointer'
                            }}
                            onClick={handleRedirectToChat}

                        >
                            View all
                        </Typography>

                    </Box>
                    {chatLoading.value && <LinearLoader progress={chatProgress} />}

                    {!chatLoading.value && <>

                        {
                            chats.length > 0 ?
                                <Scrollbar sx={{ height: 295, }}>
                                    {
                                        chats.map(({ _id, lastMessage, unreadCount, user }, index) => (
                                            <ListItem onClick={() => handleRedirectToChat(_id)}>
                                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                                    <Avatar
                                                        src={user?.picture || ""}
                                                        sx={{
                                                            width: '26px',
                                                            height: '26px',
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                            backgroundColor: 'primary.main',
                                                            color: 'primary.100'
                                                        }}>
                                                        {user?.name ? user.name[0].toUpperCase() : 'U'}
                                                    </Avatar>

                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                color: 'grey.800'
                                                            }}>
                                                            {user?.name}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 600,
                                                                color: unreadCount === 0 ? 'grey.600' : 'grey.1100'

                                                            }}>
                                                            {lastMessage[0]?.message?.length > 15 ? `${lastMessage[0]?.message?.substring(0, 15)}...` : lastMessage[0]?.message || ""}
                                                        </Typography>
                                                    }
                                                />
                                                <Box sx={{
                                                    display: 'flex',
                                                    paddingRight: '12px',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end'
                                                }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            color: 'grey.600'
                                                        }}>
                                                        {formatDate(lastMessage[0]?.createdAt) || ""}
                                                    </Typography>

                                                    {unreadCount > 0 && < Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '16px',
                                                        height: '16px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'success.300'
                                                    }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '10px',
                                                                fontWeight: 500,
                                                                color: 'success.dark'
                                                            }}>
                                                            {unreadCount !== 0 ? unreadCount : ""}

                                                        </Typography>
                                                    </Box>}
                                                </Box>
                                            </ListItem>
                                        ))
                                    }
                                    {/* </List> */}
                                </Scrollbar> :
                                <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: 'grey.500',
                                            maxWidth: '360px',
                                            textAlign: 'center'
                                        }}>
                                        No Chat yet. Create Your first Chat.
                                    </Typography>
                                    <Button
                                        sx={{
                                            fontSize: '12px',
                                            color: 'primary.light',
                                            fontWeight: 600,
                                            backgroundColor: 'primary.200',
                                            mt: '8px',
                                            padding: '8px !important'
                                        }}
                                        onClick={handleRedirectToChat}
                                        startIcon={
                                            <SvgIcon sx={{
                                                width: '16px',
                                                height: '16px',
                                                ml: '2px'
                                            }}>
                                                <CalendarEventFillIcon color={"currentColor"} />
                                            </SvgIcon>
                                        }
                                    >

                                        Send Message
                                    </Button>
                                </Stack>
                        }
                    </>}


                </Box>
            </Grid>
        </Grid >
    )
}

export default MailNChatSection