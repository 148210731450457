import { useEffect, useState } from "react"
import { setItem } from "../../lib/cookies";
import jwtDecode from "jwt-decode";
import { axiosPostCall } from "../../services/apisCall";
import { Box } from "@mui/system";
import BroadcastSettings from "../users/broadcast/settings";
import { CircularProgress } from "@mui/material";
import { decodeToken, getCurrentSessionToken } from "../../utils/common";

const BroadcastSetting = () => {

    const [user, setUser] = useState(null);

    const fetchCurrentUser = async () => {
        try {
            const consoleToken = decodeToken();

            const { vb } = consoleToken;

            if (!vb) {

                const st = {
                    "session-token": getCurrentSessionToken(),
                }
                const payload = {
                    key: "voice",
                    token: consoleToken,
                }
                const { token } = await axiosPostCall("sessionSharing", payload, false, st);

                setItem("token", token);

                const { vb: voiceUser } = jwtDecode(token);
                setUser(voiceUser)
            }
            else {
                setUser(vb);
            }

        } catch (error) {
            console.error("error in fetching current error");
        }

    }

    useEffect(() => {
        fetchCurrentUser();
    }, [])

    return (
        user ? <Box
            sx={{
                width: "500px"
            }}
        >
            <BroadcastSettings user={user} /></Box>
            :
            <Box sx={{ height: "200px", justifyContent: "center", alignItems: "center", display: 'flex' }}>
                <CircularProgress />
            </Box>

    )
}

export default BroadcastSetting;