
// eslint-disable-next-line
const QuestionMarkCircleLineIcon = ({ color }) => {
    return (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57841 12.422 0 8 0ZM8 14.8837C4.20431 14.8837 1.11628 11.7957 1.11628 8C1.11628 4.20428 4.20431 1.11628 8 1.11628C11.7957 1.11628 14.8837 4.20428 14.8837 8C14.8837 11.7957 11.7957 14.8837 8 14.8837Z" fill={color} />
            <path d="M7.7632 10.1226C7.32092 10.1226 6.96289 10.4911 6.96289 10.9334C6.96289 11.3652 7.31039 11.7442 7.7632 11.7442C8.21602 11.7442 8.57402 11.3652 8.57402 10.9334C8.57402 10.4911 8.20545 10.1226 7.7632 10.1226Z" fill={color} />
            <path d="M7.90018 3.9834C6.47856 3.9834 5.82568 4.82587 5.82568 5.39449C5.82568 5.80518 6.17318 5.99474 6.4575 5.99474C7.02615 5.99474 6.7945 5.18387 7.86859 5.18387C8.39509 5.18387 8.81634 5.41555 8.81634 5.89996C8.81634 6.46859 8.22662 6.79502 7.87912 7.08987C7.57371 7.35309 7.17359 7.78487 7.17359 8.69049C7.17359 9.23806 7.32103 9.39602 7.75275 9.39602C8.26871 9.39602 8.37403 9.16437 8.37403 8.96424C8.37403 8.41668 8.38456 8.10077 8.96375 7.64796C9.24806 7.42684 10.1431 6.71074 10.1431 5.7209C10.1431 4.73105 9.24806 3.9834 7.90018 3.9834Z" fill={color} />
        </svg>

    )
}

export default QuestionMarkCircleLineIcon