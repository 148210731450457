import React, { useContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { configFile } from "../../../../config";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  // FormControlLabel,
  // Checkbox,
  // FormControl,
  Link,
  // FormHelperText,
  useTheme,
} from "@mui/material";
import { PLATFORM_MAPPER } from "../../../../config/commonConfig";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import { axiosGetCall, axiosPostCall } from "../../../../services/apisCall";
import AuthFooter from "../../../../layouts/AuthFooter";
import ErrorMessage from "../../../../utils/ErrorMessage.json";
import {
  setSecureLoginSession,
  validateDomain,
  validateEmail,
  validatePassword,
} from "../../../../utils/common";
import Logger from "../../../../lib/Logger";

import styled from "@emotion/styled";
import { renderErrorMessage } from "../../../../lib/toastMessage";
import { handleRedirect } from "../../../../utils/common";
import LogoBlue from "../../../../assets/svgs/logo";
import { BrandingContext } from "../../../../context/brandingContext";
import SliderNBranding from "../../sliderNBranding";

// import { termsAndCondition } from "../../../config/SARV/termsAndConditions";
// import { termsAndConditionUrl } from "../../../config/SARV/branding";

const { GOOGLE_AUTH_CLIENT_ID } = configFile;
// const product = currentProduct();
// const { allowedFeatures: { googleSignUp }, privacyPolicyUrl, termsAndConditionUrl } = await import(`../../../config/${product}/branding`);

// const { privacyPolicy } = await import(`../../../config/${product}/privacy`);
// const { termsAndCondition } = await import(`../../../config/${product}/termsAndCondition`);

const HeadingSection = styled("div")(({ theme }) => ({
  width: "622px",
  display: "flex",
  background: "#FFFFFF",
  padding: "40px 60px",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: "26px",
  borderBottomRightRadius: "26px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    width: "auto",
    padding: "20px 30px",
  },
}));

function GsuiteRegistrationForm() {
  const {
    palette: { mode },
    breakpoints,
  } = useTheme();
  const greaterThanMid = useMediaQuery(breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { googleAuth = false } = useContext(BrandingContext) || {};

  // const searchParams = new URLSearchParams(location.search);
  // const Domain = searchParams.get("domain");

  const logger = new Logger("Signup");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contact, setContact] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectCountryCode /* setSelectCountryCode */] = useState(91);
  // const [checkBox, setCheckBox] = useState(false);
  // const [checkError, setCheckError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [pwdLength, setPwdLength] = useState(true);
  const [pwdNumber, setPwdNumber] = useState(true);
  const [pwdUppercase, setPwdUppercase] = useState(true);
  const [pwdLowercase, setPwdLowercase] = useState(true);
  const [pwdSymbol, setPwdSymbol] = useState(true);
  const [signupLoading, setSignupLoading] = useState(false);
  const { k = "" } = location?.state || {};

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Manage signup submit call.
  const handleSignUp = async (e) => {
    try {
      e.preventDefault();

      if (!name) return setNameError(true);
      // if (!companyName) return setCompanyNameError(true);
      if (!email) return setEmailError("blank");
      if (!password) return setPasswordError("blank");
      if (!validateEmail(email)) return setEmailError("invalid");
      if (!validateDomain(email))
        return renderErrorMessage(ErrorMessage?.[4019]);


      password.length >= 8 && password.length <= 20
        ? setPwdLength(true)
        : setPwdLength(false);
      /[0-9]/.test(password) ? setPwdNumber(true) : setPwdNumber(false);
      /[A-Z]/.test(password) ? setPwdUppercase(true) : setPwdUppercase(false);
      /[a-z]/.test(password) ? setPwdLowercase(true) : setPwdLowercase(false);
      // eslint-disable-next-line
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
        ? setPwdSymbol(true)
        : setPwdSymbol(false); //eslint-disable-line

      if (!validatePassword(password)) return;
      console.log("hello i am in handle signup")


      // if (!checkBox) return setCheckError("blank");

      setSignupLoading(true);

      const payload = {
        name,
        email,
        password,
        mobile: contact,
        countryCode: selectCountryCode,
        origin: window.location.hostname,
        om: "employee",
        companyName,
      };
      if (k && PLATFORM_MAPPER[k]) payload.key = PLATFORM_MAPPER[k];
      const { message } = await axiosPostCall("register", payload);

      return navigate("/login", {
        state: { message, time: Date.now() + 10 * 1000, register: !!k },
      });
    } catch (error) {
      logger.error(error.message);
      setSignupLoading(false);
      renderErrorMessage(error?.message);
    }
  };

  const handleGoogleLogin = async (googleToken) => {
    try {
      const payload = {
        method: "googleLogin",
        token: googleToken,
        origin: window.location.hostname,
        om: "employee",
      };
      if (k && PLATFORM_MAPPER[k]) payload.key = PLATFORM_MAPPER[k];
      const { exp, sessionToken, token, workspace, accountId } = await axiosPostCall(
        "login",
        payload
      );
      if (!exp || !sessionToken || !token) {
        return renderErrorMessage(ErrorMessage?.[4001]);
      }
      setSecureLoginSession(exp, sessionToken, token, workspace.name, accountId)

      return k && PLATFORM_MAPPER[k]
        ? handleRedirect(
          PLATFORM_MAPPER[k],
          "",
          { sessionToken, exp, token },
          true,
          mode
        )
        : (window.location.href = "/");
    } catch (error) {
      logger.error(error);
      return renderErrorMessage(error.message);
    }
  };

  // const handlePrivacyClick = () => {
  //   if (privacyPolicy) return navigate("/privacy");
  //   if (privacyPolicyUrl) return window.location.href = privacyPolicyUrl;
  //   return;
  // }

  // const handleTermsAndConditionClick = () => {
  //   if (termsAndCondition) return navigate("/privacy");
  //   if (termsAndConditionUrl) window.location.href = termsAndConditionUrl;
  //   return;
  // }

  const fetchBranding = async (isPrivacy) => {
    let path = "#";
    try {
      const { privacy = "#", termsLink = "#" } = await axiosGetCall(
        "fetchBranding",
        { privacy: true }
      );
      if (isPrivacy) {
        path = privacy;
      } else {
        path = termsLink;
      }
    } catch (error) {
      console.log(error);
    } finally {
      return path && path === "#"
        ? navigate(path)
        : (window.location.href = path);
    }
  };

  const handleSubmitForm = (e) => e.preventDefault();
  // const handleCheckbox = () => {
  //   setCheckBox((prev) => !prev);
  //   checkError && setCheckError("");
  // };

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column-reverse',
        sm: 'row',
      },
    }}>
      <SliderNBranding isSinup={true} />


      <HeadingSection>
        <ToastContainer />

        <Box
          className="FormContent"
          sx={{
            // textAlign: { xs: "center", sm: "start", md: "start", lg: 'start' },
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "100%",
            mb: '40px'
          }}
          onSubmit={handleSubmitForm}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: 'center',
              width: '100%',
              mb: '15px'
            }}>
              <Box sx={{
                width: '160px',
                height: '35px',
                display: "flex",
              }}>
                <LogoBlue isAuth={true} height={35} />
              </Box>

              <Typography sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#758195',
                textAlign: "end",
              }}>
                Already have an account? {" "}
                <Typography
                  onClick={() => navigate("/login")}
                  component={"span"}
                  sx={{
                    color: '#0C66E4',
                    cursor: 'pointer'
                  }}>
                  Log In
                </Typography>
              </Typography>
            </Box>

          </Box>

          <Box
            className="FormGroup"
            sx={{
              display: "flex",
              gap: "15px",
              flexDirection: "column",
            }}
          >
            <Box
              className="FormHeading"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // p: "0px",
                gap: '6px'
              }}
            >
              <Typography
                variant="custom24600"
                sx={{
                  color: "#0055CC",
                  fontSize: "30px",
                  fontWeight: 600
                }}
              >
                Create account
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              {googleAuth && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          const token = credentialResponse.credential;
                          handleGoogleLogin(token);
                        }}
                        onError={() => {
                          return renderErrorMessage(ErrorMessage?.[4006]);
                        }}
                        context="signup"
                        text="signup_with"
                        size="large"
                        shape="circle"
                        width={greaterThanMid ? "372px" : "300px"}
                      // width={'370px'}
                      />
                    </GoogleOAuthProvider>
                  </Box>

                  <Divider>
                    <Typography
                      sx={{
                        display: "flex",
                        fontWeight: 600,
                        alignItems: "center",
                        fontSize: "12px",
                        color: '#626F86'
                      }}
                    >
                      OR SIGN UP WITH
                    </Typography>
                  </Divider>
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#44546F",
                  }}
                >
                  Email
                </Typography>

                <TextField
                  id="email"
                  variant="outlined"
                  placeholder="Enter you email"
                  fullWidth
                  size="small"
                  type={"email"}
                  error={Boolean(emailError)}
                  onClick={() => (emailError ? setEmailError("") : "")}
                  helperText={
                    emailError === "blank"
                      ? ErrorMessage?.[4003]
                      : emailError === "invalid"
                        ? ErrorMessage?.[4004]
                        : ""
                  }
                  onChange={(e) => setEmail(`${e.target.value}`)}
                  // value={email.split("@")[0]}
                  // onChange={(e) => setEmail(e.target.value)}
                  // value={email}
                  inputProps={{
                    sx: {
                      fontSize: "14px",
                      color: "#44546F",
                      borderColor: '#B3B9C4'
                    },
                  }}
                />
              </Box>


              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  p: "0px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#44546F",
                  }}
                >
                  Company
                </Typography>

                <TextField
                  id="CompanyName"
                  placeholder="Enter company name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type={"text"}
                  error={nameError}
                  onClick={() =>
                    companyNameError ? setCompanyNameError(false) : ""
                  }
                  helperText={companyNameError ? ErrorMessage?.[4003] : ""}
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  inputProps={{
                    sx: {
                      // height: "30px",
                      fontSize: "14px",
                      color: "#44546F",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  p: "0px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#44546F",
                  }}
                >
                  Name
                </Typography>

                <TextField
                  id="Name"
                  placeholder="Enter your name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type={"text"}
                  error={nameError}
                  onClick={() => (nameError ? setNameError(false) : "")}
                  helperText={nameError ? ErrorMessage?.[4003] : ""}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  inputProps={{
                    sx: {
                      // height: "30px",
                      fontSize: "14px",
                      color: "#44546F",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                  },
                  gap: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "6px",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#44546F",
                    }}
                  >
                    Phone number
                  </Typography>
                  <TextField
                    id="ContactNo"
                    placeholder="Enter phone number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={"number"}
                    error={contactError}
                    onChange={(e) => setContact(e.target.value)}
                    onClick={() => (contactError ? setContactError(false) : "")}
                    helperText={contactError ? ErrorMessage?.[4003] : ""}
                    value={contact}
                    sx={{
                      "& .MuiInput-input": {
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                        },
                      },
                    }}
                    inputProps={{
                      sx: {
                        // height: "30px",
                        fontSize: "14px",
                        color: "#44546F",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          {`+ ${selectCountryCode} `}{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "6px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#44546F",
                    }}
                  >
                    Password
                  </Typography>

                  <form style={{ width: "100%" }}>
                    <TextField
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      placeholder={"Type password"}
                      fullWidth
                      size="small"
                      autoComplete="on"
                      error={Boolean(
                        passwordError ||
                        !pwdLength ||
                        !pwdNumber ||
                        !pwdUppercase ||
                        !pwdLowercase ||
                        !pwdSymbol
                      )}
                      onClick={() => {
                        setPasswordError("");
                        setPwdLength(true);
                        setPwdNumber(true);
                        setPwdUppercase(true);
                        setPwdLowercase(true);
                        setPwdSymbol(true);
                      }}
                      helperText={
                        passwordError === "blank"
                          ? ErrorMessage?.[4002]
                          : !pwdLength
                            ? "pasword length should be 8 to 20."
                            : !pwdNumber
                              ? "At least 1 Number required."
                              : !pwdUppercase
                                ? "At least 1 Uppercase character required"
                                : !pwdLowercase
                                  ? "At least 1 Lowercase character required"
                                  : !pwdSymbol
                                    ? "At least 1 special character required"
                                    : ""
                      }
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      inputProps={{
                        sx: {
                          // height: "30px",
                          fontSize: "14px",
                          color: "#44546F",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              sx={{ color: '#44546F' }}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </form>
                </Box>
              </Box>

              <Box /* sx={{
              display: 'flex',
              flexDirection: 'row'
            }} */>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#44546F",
                  }}
                >
                  {/*  Terms of Service and Privacy Policy. */}
                  By submitting the form, you agree to our{" "}
                  <Link
                    // href={termsPath || termsAndCondition || "#"}
                    onClick={() => fetchBranding()}
                    // underline="none"
                    sx={{ color: '#44546F' }}
                    underline="always"
                    color="inherit"
                  >
                    Terms of Services {" "}
                  </Link>
                  and{" "}
                  <Link
                    // href={privacyPath || privacyPolicy || "#"}
                    onClick={() => fetchBranding(true)}
                    // underline="none"
                    sx={{ color: '#44546F' }}
                    underline="always"
                    color="inherit"

                  >
                    Privacy Policy.
                  </Link>

                </Typography>
              </Box>

              {/*  <FormControl sx={{ mt: "-14px", mb: "-14px" }}>
              <FormControlLabel
                helperText={checkError === "blank" ? ErrorMessage?.[4003] : ""}
                control={
                  <Checkbox
                    onClick={handleCheckbox}
                    sx={{ color: "#D0D0D1" }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#44546F",
                    }}
                  >
                    By submitting the form, you agree to our{" "}
                    <Link
                      // href={termsPath || termsAndCondition || "#"}
                      onClick={() => fetchBranding()}
                    // underline="none"
                    >
                      Terms of Services {" "}
                    </Link>
                    and{" "}
                    <Link
                      // href={privacyPath || privacyPolicy || "#"}
                      onClick={() => fetchBranding(true)}
                    // underline="none"
                    >
                      Privacy Policy.
                    </Link>

                  </Typography>
                }
              /> 
              <FormHelperText>
                {checkError === "blank" ? ErrorMessage?.[4003] : ""}
              </FormHelperText>
            </FormControl>
            */}


              <AuthFooter
                btnText={"Create your Account"}
                navigationMessage={"Already have an account?"}
                navigateText={"Log in"}
                btnCheckHandler={handleSignUp}
                // navigationCheckHandler={() => navigate("/login")}
                loadingBtn={signupLoading}
              />
            </Box>
          </Box>
        </Box>
      </HeadingSection>

    </Box>
  );
}

export default GsuiteRegistrationForm;
