import { Box, Button, IconButton, SvgIcon, Typography } from '@mui/material'
import React, { useState } from 'react'
import QuestionMarkCircleLineIcon from '../../assets/svgs/icons/question-mark-circle-line'
import LogoBlue from '../../assets/svgs/logo';
import CloseIcon from '@mui/icons-material/Close';

const Help = () => {
    const [openPopup, setOpenPopup] = useState(false);

    return (
        <>
            {
                !openPopup ?
                    <Button
                        sx={{
                            background: "linear-gradient(87.59deg, #0052CC 1.66%, #74607D 46.6%, #EC6F2B 93.37%)",
                            position: 'fixed',
                            bottom: { xs: 20, sm: 26 },
                            right: { xs: 10, sm: 26 },
                            color: '#FFFFFF',
                            borderRadius: '100px',
                            padding: '7px 15px',
                            gap: '-15px',
                            zIndex: 9999
                        }}
                        onClick={() => setOpenPopup(true)}
                        startIcon={
                            <IconButton sx={{ color: '#FFFFFF', padding: '0px' }} size='small' >
                                <SvgIcon sx={{ color: 'inherit', width: '14px', height: '14px' }}>
                                    <QuestionMarkCircleLineIcon color={"currentColor"} />
                                </SvgIcon>
                            </IconButton>
                        }
                    >
                        Help
                    </Button>
                    :
                    <Box sx={{
                        height: '134px',
                        width: '217px',
                        border: '1px solid #DCDFE4',
                        borderRadius: '12px',
                        gap: '2px',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'fixed',
                        bottom: { xs: 20, sm: 26 },
                        right: { xs: 10, sm: 26 },
                        padding: '16px',
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        zIndex: 9999
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#2C3E5D' }}>
                                Hi there!
                            </Typography>

                            <IconButton sx={{ width: '26px', height: '26px' }} onClick={() => setOpenPopup(false)}>
                                <CloseIcon sx={{ width: '16px', height: '16px' }} />
                            </IconButton>

                        </Box>

                        <Typography sx={{ fontWeight: 600, fontSize: '12px', color: '#44546F' }}>
                            Difficulties while navigating? {" "}
                            <Typography component={"span"} sx={{ fontWeight: 400, fontSize: '12px', color: '#44546F' }}>
                                Refer to these user guides for quick help.
                            </Typography>

                            <Typography
                                component={"span"}
                                sx={{ fontWeight: 500, fontSize: '12px', color: '#1D7AFC', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => window.location.href = "https://sarv.com/contact"}
                            >
                                {" "}Click here!
                            </Typography>
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '29px',
                            background: 'linear-gradient(87.59deg, #0052CC 1.66%, #74607D 46.6%, #EC6F2B 93.37%)',
                            mr: '-16px',
                            ml: '-16px',
                            mt: '4px',
                            padding: '5px 16px'
                        }}>
                            <LogoBlue isAuth={true} height={16} />

                            <Typography sx={{ fontWeight: 600, fontSize: '12px', color: '#ffffff' }}>
                                Help Guide
                            </Typography>
                        </Box>
                    </Box >

            }
        </>

    )
}

export default Help