import { styled } from "@mui/material";
import UploadBrandingImage from './uploadBox';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const BrandingAccordion = ({ title, brandingKey, handleFileChange, handleRemoveLogo, brandingUrls }) => {
    const brandingImages = [
        // { name: 'favicon', label: 'Upload Favicon', dimensions: '130x32px', propKey: 'faviconUrl' },
        { name: 'logo', label: 'Upload logo image/svg for light theme', dimensions: '130x32px', propKey: 'logoUrlLight' },
        { name: 'darkLogo', label: 'Upload logo image/svg for dark theme', dimensions: '130x32px', propKey: 'logoUrlDark' },
    ];
   /*  const ConsoleBrandingImages = [
        { name: 'favicon', label: 'Upload Favicon', dimensions: '130x32px', propKey: 'faviconUrl' },
        { name: 'logoWithoutName', label: 'Upload logo without name', dimensions: '32x32px', propKey: 'logoUrlIcon' },
    ]; */


    

    return (
        <Accordion
            sx={{
                width: '100%',
                '&::before': { display: 'none' },
            }}
        >
            <AccordionSummary aria-controls={`${brandingKey}-content`} id={`${brandingKey}-header`}>
                {title}
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <RowTow>
                    {brandingImages.map(({ name, label, dimensions, propKey }) => (
                        <UploadBrandingImage
                            key={name}
                            name={`${brandingKey}${name.charAt(0).toUpperCase() + name.slice(1) }`}
                            label={label}
                            handleFileChange={handleFileChange}
                            imageDesc={`Logo image size: <5MB, ${dimensions} dimensions`}
                            onRemove={() => handleRemoveLogo(brandingKey, `${brandingKey}${propKey.charAt(0).toUpperCase() + propKey.slice(1)}`)}
                            imageUrl={brandingUrls?.[brandingKey]?.[`${brandingKey}${propKey.charAt(0).toUpperCase() + propKey.slice(1) }`]}
                        />
                    ))}
                </RowTow>
            </AccordionDetails>
        </Accordion>
    );
};
export default BrandingAccordion

