import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import ReferenceLoginSection from '../sections/refereceLogin';

const ReferenceLogin = () => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: transparent; }'}</style>
            </Helmet>
            
            <Box sx={{ backgroundColor: 'transparent' }}>

                <ReferenceLoginSection />
            </Box>
        </>
    )
}

export default ReferenceLogin;
