import { Box } from "@mui/material";
import React, { useEffect } from "react";

import { decodeToken, fetchRefLoginPath } from "../utils/common";

const AddAccount = ({key}) => {
    // const { breakpoints, /* palette: { mode } */ } = useTheme();
    // if (!getItem("refSecureToken")) {
    //     window.location.href = "/"
    // }
    // const greaterThanMid = useMediaQuery(breakpoints.up("sm"));
    const { parentUserId, parentSession } = decodeToken();

useEffect(() => {
        const handleMessage = (event) => {
            const eventHost = new URL(event.origin).hostname;
            if (eventHost === window.location.hostname) {
                const { message, redirectPath } = event.data;
                if(message === "LOGIN_SUCCESS") {
                    window.location.href = redirectPath;
                }
            }
        };
        window.addEventListener('message', handleMessage);
        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <Box width={"100%"} height={"100vh"}>
            <iframe
                title="voice"
                id="voice"
                src={`${ fetchRefLoginPath()}/login?authUser=${parentUserId}&authSession=${parentSession}&key=${key || ""}`}
                allow="camera *;microphone *"
                style={{
                    top: " 0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                    border: "0px",
                }}
            />
        </Box>
    )
}

export default AddAccount;