import {
  // Button,
  FormControl,
  // FormControlLabel,
  // FormLabel,
  // Grid,
  // Icon,
  IconButton,
  InputLabel,
  // Link,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import FormProvider from "../../components/hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Iconify from "../../components/iconify";
import RHFTextField from "../../components/hook-form/rhf-text-field";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
// import { axiosPostCall } from "../services/apisCall";
import { RHFCheckbox } from "../../components/hook-form/rhf-checkbox";
import { Collapse } from "@mui/material";
import Logger from "../../lib/Logger";
const logger = new Logger("contactus form");

/* const CardsMainSection = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  gap: "14px",
  //   padding: '76px 12px 12px 70px',
  // height: '100%',
  //   height: window.innerHeight,
  //   overflowY: 'scroll',
  //   [theme.breakpoints.down('sm')]: {
  //     padding: '70px 14px 14px 14px',
  //     height: 'auto',
  //   },
})); */
const BillingAddress = ({
  billingAddress,
  handleBillingAddress,
  currentStep,
  handleCurrentStep,
}) => {
  const [loading, setLoading] = useState(false);
  const NewCampaignSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    mobile: Yup.number().required("Mobile Number is required."),
    company: Yup.boolean(),
    companyName: Yup.string().when("company", {
      is: true,
      then: (schema) => schema.required("Company name is required"),
      otherwise: (schema) => schema.optional(),
    }),
    companySize: Yup.string().when("company", {
      is: true,
      then: (schema) => schema.required("Company Size is required"),
      otherwise: (schema) => schema.optional(),
    }),
  });
  const methods = useForm({
    resolver: yupResolver(NewCampaignSchema),
    defaultValues: {
      userType: "individual",
      company: false,
    },
  });

  const {
    // setValue,
    handleSubmit,
    control,
    watch,
  } = methods;

  /* const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: (theme) => theme.palette.customGrey[100],
    borderRadius: "10px",
    boxShadow: 24,
    p: "15px 20px",
  }; */

  const onSubmit = async (data) => {
    try {
      handleBillingAddress(data);
      handleCurrentStep("payment");
      // const formData = data;
      // await axiosPostCall("leads", { formData })
      setLoading(false);
    } catch (error) {
      logger.error(error);
    }
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const values = watch();
  return (
    <>
      {currentStep === "payment" && (
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"15px"}
        >
          <Typography variant="subtitle1">Address</Typography>
          <Stack
            direction={"row"}
            gap={0.5}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => handleCurrentStep("address")}
          >
            {/* <Typography variant="subtitle2" sx={{
                        color: (theme) => theme.palette.secondary?.main,
                        cursor: "pointer",
                        "&:hover": {
                            color: (theme) => theme.palette.secondary?.darker
                        }
                    }}>Edit</Typography> */}
            <IconButton
              disableRipple
              sx={{
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            >
              <Iconify icon="mingcute:down-line" />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {/* <Collapse in={currentStep !== "payment"}>
        <Stack width={"100%"} sx={{ overflow: "hidden" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack width={"100%"} gap={1} p={2}>
              <Typography variant="subtitle1">Address</Typography>
              <Stack>
                <Stack gap={2}>
                  <RHFTextField name="name" label="Name*" size="small" />
                  <RHFTextField name="email" label="Email*" size="small" />
                  <RHFTextField
                    name="mobile"
                    label="Mobile Number*"
                    size="small"
                  />
                  <RHFTextField
                    name="streetAddress"
                    label="House number and street name *"
                    size="small"
                  />
                  <RHFTextField
                    name="streetAddress2"
                    label="Apartment, suite, unit, etc. (optional)"
                    size="small"
                  />
                  <Stack width={"100%"} direction={"row"} gap={1}>
                    <RHFTextField
                      name="pincode"
                      label="Zip/Postal*"
                      size="small"
                    />
                    <RHFTextField name="city" label="City*" size="small" />
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="state-label" size="small">
                        State*
                      </InputLabel>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            labelId="state-label"
                            size="small"
                            label="State"
                            {...field}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {indianStates.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      
                    </FormControl>
                  </Stack>
                  <RHFCheckbox
                    name="company"
                    label={"This is Business Account"}
                  />
                  {values?.company && (
                    <RHFTextField
                      name="companyName"
                      label="Company Name*"
                      size="small"
                    />
                  )}
                  {values?.company && (
                    <RHFTextField
                      name="companySize"
                      label="Company Size*"
                      size="small"
                    />
                  )}
                  <RHFTextField
                    name="gstNumber"
                    label="GST Number"
                    size="small"
                  />
                  <Stack justifyContent={"left"}>
                    <LoadingButton
                      loading={loading}
                      sx={{ boxShadow: "none" }}
                      type="submit"
                      color="primary"
                      size="medium"
                      variant="contained"
                    >
                      Continue
                    </LoadingButton>
                  </Stack>
                </Stack> */}

      <Collapse in={currentStep !== "payment"}>
        <Stack width={"100%"} sx={{ overflow: "hidden" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack width={"100%"} gap={1} p={2}>
              <Typography variant="subtitle1">Address</Typography>
              <Stack>
                <Stack gap={2}>
                  <RHFTextField name="name" label="Name*" size="small" />
                  <RHFTextField name="email" label="Email*" size="small" />
                  <RHFTextField
                    name="mobile"
                    label="Mobile Number*"
                    size="small"
                  />
                  <RHFTextField
                    name="streetAddress1"
                    label="House number and street name *"
                    size="small"
                  />
                  <RHFTextField
                    name="streetAddress2"
                    label="Apartment, suite, unit, etc. (optional)"
                    size="small"
                  />
                  <Stack width={"100%"} direction={"row"} gap={1}>
                    <RHFTextField
                      name="pincode"
                      label="Zip/Postal*"
                      size="small"
                    />
                    <RHFTextField name="city" label="City*" size="small" />
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="state-label" size="small">
                        State*
                      </InputLabel>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            labelId="state-label"
                            size="small"
                            label="State"
                            {...field}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {indianStates.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {/* <FormHelperText>{errors.mySelect?.message}</FormHelperText> */}
                    </FormControl>
                  </Stack>
                  <RHFCheckbox
                    name="company"
                    label={"This is Business Account"}
                  />
                  {values?.company && (
                    <RHFTextField
                      name="companyName"
                      label="Company Name*"
                      size="small"
                    />
                  )}
                  {values?.company && (
                    <RHFTextField
                      name="companySize"
                      label="Company Size*"
                      size="small"
                    />
                  )}
                  <RHFTextField
                    name="gstNumber"
                    label="GST Number"
                    size="small"
                  />
                  <Stack justifyContent={"left"}>
                    <LoadingButton
                      loading={loading}
                      sx={{ boxShadow: "none" }}
                      type="submit"
                      color="primary"
                      size="medium"
                      variant="contained"
                    >
                      Continue
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Collapse>
    </>
  );
};

export default BillingAddress;
