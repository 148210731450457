import React, { useContext, useEffect, useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, AvatarGroup, Avatar, IconButton, Tooltip, useTheme, Box, SvgIcon } from '@mui/material';
import HiSvg from '../../assets/svgs/hiSvg';
// import DocumnetFileCopyIcon from '../../assets/svgs/documnetFileCopyIcon';
import Logger from '../../lib/Logger';

// import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import { axiosPostCall } from '../../services/apisCall';
// import { getItem } from '../../lib/cookies';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
// import jwtDecode from 'jwt-decode';
import { decodeToken, handleRedirect, nameFormater } from '../../utils/common';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorMessage from "../../utils/ErrorMessage.json"
import { BrandingContext } from '../../context/brandingContext';
import FileCopyLineIcon from '../../assets/svgs/icons/file-copy-line';
import LockUnlockLineIcon from '../../assets/svgs/icons/lock-unlock-line';
import LockLineIcon from '../../assets/svgs/icons/lock-line';
const logger = new Logger("meetOpenRoomSection")

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 19px',
    borderRadius: "8px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[300],
    // width: 'calc(100% / 1.6)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '16px 19px',
        gap: '5px',
        flexDirection: 'column',
        
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        gap: '5px',
    },
}));

const CardsLeftSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '19px',
    width: 'calc(100% / 2.8)',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const CardsRightSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 100% / 2.8)',
    flexDirection: 'row',
    gap: '6px',
    [theme.breakpoints.down('sm')]: {
        // display: 'none'
        width: '100%',
        justifyContent: 'flex-start',
    },

}));

const CardsRightBottomSection = styled('div')(({ theme }) => ({
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '6px',
    marginLeft: '10px',
    [theme.breakpoints.up('sm')]: {
        // display: 'none'
    },
}));

const HeadingSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '16px'
}))

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[800],
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px'
}))

// const SubHeading = styled(Typography)(({ theme }) => ({
//     color: theme.palette.grey[400],
//     fontSize: '18px',
//     fontWeight: 400,
//     lineHeight: '18px'
// }))

const InviteSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%'
    },
}))

const InviteUrl = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    // gap: '50px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '6px 14px',
    border: '1px solid',
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[0],
    width: '320px',

    [theme.breakpoints.down('md')]: {
        width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
        gap: '10px',
        width: '100%',
    },
}))

const InviteUrlTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '13px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    maxWidth: '200px',

    [theme.breakpoints.down('md')]: {
        maxWidth: '300px',
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '300px',
    },
}));

const CustomBuuton = styled(LoadingButton)(({ theme }) => ({
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: theme.palette.primary[200],
    border: 'none',
    color: theme.palette.primary.main,
}));

const LockIconButton = styled(IconButton)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "light" ? theme.palette.button.dark : theme.palette.grey[700],
    // color: theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.grey[100],
    // '&:hover': {
    //     backgroundColor: theme.palette.mode === "light" ? theme.palette.button.dark : theme.palette.grey[700],
    // }
}));

const JoinTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '16px',
    fontWeight: 500,
}));

const InviteActionsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
    }
}));

const MeetOpenRoomSection = ({ attendeeCount, attendees, joinUrl, roomId, visibility, setVisibility, loader }) => {
    const [userName, setUserName] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [timer, setTimer] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const theme = useTheme();
    const { companyBrandName = "" } = useContext(BrandingContext)

    const handleVisibility = async () => {
        try {
            const payload = {
                methods: ['meet'],
                meet: {
                    roomId,
                    public: !visibility
                }
            }
            setLoadingVisibility(true);
            await axiosPostCall("consoleDetails", payload);
            setLoadingVisibility(false);
            setVisibility((prev) => !prev);
        }
        catch (error) {
            setLoadingVisibility(false);
            logger.error("meetOpenRoomSection fn: [handleVisibility] api:[consoleDetails] post catchError", error)
        }
    }

    const handleJoin = async () => {
        try {
            setLoadingBtn(true);
            await handleRedirect("meet", "custom_session", {}, true, theme.palette.mode, "join", roomId);
            setLoadingBtn(false);
        }
        catch (error) {
            setLoadingBtn(false);
            logger.error("meetOpenRoomSection fn: [handleJoin] api:[shareSession] post catchError", error);
        }
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(joinUrl);
            setIsCopied(true);

            if (timer) clearTimeout(timer);

            const timerId = setTimeout(() => {
                setIsCopied(false);
            }, 10000);

            setTimer(timerId);
        } catch (error) {
            setIsCopied(false);
            logger.error('meetOpenRoomSection fn: [handleVisibility] Unable to copy to clipboard', error)
        }
    };

    useEffect(() => {
        try {
            const { name = "User" } = decodeToken();
            setUserName(name);
        } catch (error) {
            logger.error("meetOpenRoomSection.jsx useEffect jwtDecode", error)
        }
    }, []);

    return (
        <CardsMainSection >
            <CardsLeftSection>

                <HeadingSection>
                    <SvgIcon sx={{
                        width: '30px',
                        height: '30px',
                    }}>
                        <HiSvg />
                    </SvgIcon>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Heading>
                            Hi {nameFormater(userName)},
                        </Heading>
                        <Typography sx={{
                            fontSize: "12px",
                            color: 'gray.600',
                            fontWeight: 500
                        }}>
                            Welcome to the <strong>{companyBrandName} console</strong>
                        </Typography>
                    </Box>

                </HeadingSection>

                {/* <SubHeading>
                    Your always on meeting room
                </SubHeading> */}


            </CardsLeftSection>

            <CardsRightSection>
                <InviteSection>
                    {!loader ?
                        <InviteUrl>
                            <InviteUrlTitle>
                                {joinUrl ? joinUrl : ErrorMessage?.[4000]}
                            </InviteUrlTitle>

                            <Box>
                                <LockIconButton disableTouchRipple disabled={loadingVisibility} onClick={() => handleVisibility()}>
                                    <SvgIcon sx={{
                                        width: '16px',
                                        height: '16px',
                                    }}>
                                        {visibility ? <LockUnlockLineIcon color={"currentColor"} /> : <LockLineIcon color={"currentColor"} />}
                                    </SvgIcon>
                                </LockIconButton>

                                <Tooltip
                                    open={isCopied}
                                    onOpen={() => {
                                        setIsCopied(true);
                                    }}
                                    onClose={() => {
                                        setIsCopied(false);
                                    }}

                                    title="Copied to clipboard!"
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    arrow
                                >
                                    <IconButton
                                        onClick={() => copyToClipboard()}
                                        size="small"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {/* <DocumnetFileCopyIcon colour={isCopied && theme.palette.success.main} /> */}
                                        <SvgIcon sx={{
                                            width: '16px',
                                            height: '16px',
                                        }}>
                                            <FileCopyLineIcon color={"currentColor"} />
                                        </SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </InviteUrl>
                        :
                        <Box
                            sx={{
                                width: "300px",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",

                            }}
                        >

                            <CustomSkeleton variant={"rounded"} height={"30px"} width={"100%"} />
                        </Box>
                    }
                    <InviteActionsBox>

                        <CustomBuuton
                            variant='outlined'
                            onClick={() => handleJoin()}
                            loading={loadingBtn}
                        >
                            Join
                        </CustomBuuton>

                        <CardsRightBottomSection>
                            {!loader ? attendees.length > 0 ? <><JoinTitle sx={{ fontSize: '10px', fontWeight: 500, color: 'grey.600' }}>
                                Already joined
                            </JoinTitle>

                                <AvatarGroup
                                    max={1}

                                    total={attendeeCount || 0}
                                    sx={{
                                        '&.MuiAvatarGroup-root .MuiAvatar-root': {
                                            border: '1px solid',
                                            borderColor: 'grey.700',
                                            color: "grey.400",
                                            backgroundColor: 'grey.800',
                                            width: '24px',
                                            height: '24px',
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    {
                                        attendees.length > 0 && attendees.slice(0, 3).map(({ displayName }, index) => (
                                            <Avatar key={index} alt={displayName} src="/static/images/avatar/1.jpg" />
                                        ))
                                    }
                                </AvatarGroup> </> :
                                <JoinTitle sx={{ fontSize: '10px', fontWeight: 500, color: 'grey.600' }}>
                                    No one joined yet!
                                </JoinTitle>
                                :
                                <CustomSkeleton variant={"circular"} width={40} height={40} />}

                        </CardsRightBottomSection>
                    </InviteActionsBox>

                </InviteSection>

                {/* {!loader ? attendees.length > 0 ? <><JoinTitle>
                    Already joined
                </JoinTitle>

                    <AvatarGroup
                        max={3}
                        total={attendeeCount || 0}
                        sx={{
                            '&.MuiAvatarGroup-root .MuiAvatar-root': {
                                border: '1px solid',
                                borderColor: 'grey.700',
                                color: "grey.400",
                                backgroundColor: 'grey.800'
                            }
                        }}
                    >
                        {attendees.slice(0, 3).map(({ displayName }) => (
                            <Avatar alt={displayName} src="/static/images/avatar/1.jpg" />
                        ))}
                    </AvatarGroup> </> : <JoinTitle sx={{ fontSize: '12px' }}>
                    No one joined yet!
                </JoinTitle> :
                    <CustomSkeleton variant={"circular"} width={60} height={60} />
                } */}
            </CardsRightSection>
        </CardsMainSection>
    )
}

export default MeetOpenRoomSection;
