import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MeetOpenRoomSection from '../../sections/dashboard/meetOpenRoomSection';
import AccountManagerSection from '../../sections/dashboard/accountManagerSection';
// import UpcomingMeetSection from '../../sections/dashboard/upcomingMeetSection';
// import PendingInvoice from '../../sections/dashboard/pendingInvoice';
// import SupportTicket from '../../sections/dashboard/supportTicket';
// import NotificationSection from '../../sections/dashboard/notificationSection';
// import DeepCallSection from '../../sections/dashboard/deepCallSection';
// import SmsSection from '../../sections/dashboard/smsSection';
// import VoiceSection from '../../sections/dashboard/voiceSection';
// import AdLeftSection from '../../sections/dashboard/adLeftSection';
// import AdRightSection from '../../sections/dashboard/adRightSection';
import { axiosGetCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
import { dayAndMontBetweenDate, decodeToken, getCurrentPath } from '../../utils/common';
import KycSectionData from '../../sections/dashboard/kyc';
import MailNChatSection from '../../sections/dashboard/mailNChat';
import { getLocalStorageItem } from '../../lib/localStorage';
import DriveSection from '../../sections/dashboard/driveSection';
import MeetNWebinar from '../../sections/dashboard/meetNWebinarSection';
import SmsCampSection from '../../sections/dashboard/smsCampSection';
import useLoader from '../../hooks/use-loader';
import { SnackbarProvider } from '../../components/snackbar';

const logger = new Logger("Dashboard");

const CardsMainSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '14px',
  padding: '62px 12px 12px 72px',
  height: 'auto',
  // height: window.innerHeight/* '758px' */,
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    padding: '70px 14px 14px 14px',
    height: 'auto',
  },
}));

const KycSection = styled('div')(({ theme }) => ({
  height: '48px',
  display: 'flex',
  // justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: '14px',
  backgroundColor: theme.palette.success[100],
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: theme.palette.success[200],
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'auto'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    // height: 'auto'
  },
}));

const SectionOne = styled('div')(({ theme }) => ({
  // height: '20vh',
  height: '70px', //152px
  // maxHeight: '152px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: '14px',
  // backgroundColor: theme.palette.grey[300],
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'auto'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    height: 'auto'
  },
}));

/* const SectionTwo = styled('div')(({ theme }) => ({
  // height: '20vh',
  height: '152px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: '14px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: 'auto'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    height: '170px'
  },
})); */

/* const SectionThree = styled('div')(({ theme }) => ({
  // height: '40vh',
  // height: '500px', //304px
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '14px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto'
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    // height: '500px',
    flexDirection: 'column',
    // overflowY: 'hidden'
  },
})); */

// const SectionFour = styled('div')(({ theme }) => ({
//   // height: '20vh',
//   height: '152px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   flexDirection: 'row',
//   gap: '14px',
//   width: '100%',
//   [theme.breakpoints.down('sm')]: {
//     flexDirection: 'column',
//     height: 'auto'
//   },
//   [theme.breakpoints.between('sm', 'lg')]: {
//     height: '170px'
//   },
// }));

/* const PendingInvoiceOuterSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '14px',
  flexDirection: 'row',
  height: '100%',
  width: 'calc(100% / 4 - 5px)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: 'calc(100% / 3 - 5px)',
  },
}));

const SmsAndVoiceSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '14px',
  flexDirection: 'row',
  height: '100%',
  width: 'calc(100% / 2)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
    height: 'calc(100% / 2)',
  },
})); */

export default function Dashboard() {

  const [attendeeCount, setAttendeecount] = useState(0);
  const [attendees, setAttendees] = useState([]);
  const [joinUrl, setJoinUrl] = useState("");
  const [roomId, setRoomId] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [AMName, setAMName] = useState("");
  const [AMprofileUrl, setAMProfileUrl] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNO] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [meetDoc, setMeetDoc] = useState([]);
  const [webinarDoc, setWebinarDoc] = useState([]);
  const [title, setTile] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState(0);
  const [, setMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  // const [sheduleMeetRoomId, setScheduleMeetRoomId] = useState("");
  const [AMloader, setAMloader] = useState(true);
  const [/* mobileAd */, setMobileAd] = useState("");
  const [/* desktopAd */, setDesktopAd] = useState("");
  const [/* adPath */, setAdPath] = useState("");

  const [callAnalyticsLabel, setCallAnalyticsLabel] = useState(dayAndMontBetweenDate(15));
  const [callDetailsSeries, setCallDetailsSeries] = useState([]);

  const [runningCampaign, setRunningCampaign] = useState([]);

  const [runningShow, setRunningShow] = useState(true);
  const [notVoiceUser, setNotVoiceUser] = useState(false);

  const [chats, setChats] = useState([]);
  const [mails, setMails] = useState({});
  const [driveData, setDriveData] = useState([]);
  const [jwtData, setJwtData] = useState({});
  const [load, setLoad] = useState(false);
  const [documentType, setDocumentType] = useState("all");

  const chatLoader = useLoader(0);
  const mailLoader = useLoader(0);
  const driveLoader = useLoader(0);
  const meetLoader = useLoader(0);
  const webinarLoader = useLoader(0);
  const voiceLoader = useLoader(0);
  const { products = [] } = jwtData;

  const [highlight, setHighlight] = useState(false);

  const handleHighlight = () => {
    setHighlight(true);
    setTimeout(() => setHighlight(false), 5000);
  };

  const meetDetails = async () => {
    try {
      const payload = JSON.stringify(["meet"]);
      const { dashboardDeatils: {
        meet: { attendeeCount = 0, attendees = [], public: isPublic = true,
          joinUrl = "", roomId = "", meetDoc = []
        } = {} } } = await axiosGetCall("consoleDetails", { 'methods': payload })

      setAttendeecount(attendeeCount);
      setAttendees(attendees);
      setJoinUrl(`https://${window.location.hostname}${getCurrentPath()}/${joinUrl}`);
      setRoomId(roomId);
      setMeetDoc(meetDoc)
      setTile(title);
      // setStartDate(startDate);
      // setEndDate(endDate);
      setLoader(false);
      setVisibility(isPublic);
      // setScheduleMeetRoomId(sheduleRoomId);

    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [meetDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const webinarDetails = async () => {
    try {
      const payload = JSON.stringify(["webinar"]);
      const { dashboardDeatils: {
        webinar: { meetDoc: webinarDoc = [] } = {} } } = await axiosGetCall("consoleDetails", { 'methods': payload })

      setWebinarDoc(webinarDoc);
    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [webinarDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const getVoiceDetail = async () => {
    try {
      const payload = JSON.stringify(["voice"]);
      const { dashboardDeatils: { voice: { code = 200, callDetails: { totalAnswered = [], totalaUnanswered = [], totalOther = [], totalValid = [] } = {},
        running: {
          runningAnswered = 0,
          runningUnanswered = 0,
          runningOther = 0,
          runningQueue = 0,
          runningSending = 0,
        } = {},
        label, } } } = await axiosGetCall("consoleDetails", { 'methods': payload });

      setCallAnalyticsLabel(label);
      if (code === 404) setNotVoiceUser(true);

      if (runningAnswered === 0 && runningUnanswered === 0 && runningOther === 0 && runningQueue === 0 && runningSending === 0) setRunningShow(false);

      const runningStatus = [
        { label: 'Answered', value: runningAnswered || 0 },
        { label: 'Dialing', value: runningSending || 0 },
        { label: 'In-Queue', value: runningQueue || 0 },
        { label: 'Other', value: runningOther || 0 },
        { label: 'No Answered', value: runningUnanswered || 0 },
      ];

      const callDetailsSeries = [
        {
          name: 'Dialed',
          type: 'column',
          fill: 'solid',
          data: totalValid,
        },
        {
          name: 'Answered',
          type: 'line',
          fill: 'solid',
          data: totalAnswered,
        },
        {
          name: 'No Answered',
          type: 'line',
          fill: 'solid',
          data: totalaUnanswered,
        },
        {
          name: 'Other',
          type: 'line',
          fill: 'solid',
          data: totalOther,
        },
      ]

      setCallDetailsSeries(callDetailsSeries);
      setRunningCampaign(runningStatus);

    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [getVoiceDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const getChatDetail = async () => {
    try {
      const payload = JSON.stringify(["chat"]);
      const { dashboardDeatils: { chat: { chats = [] } } } = await axiosGetCall("consoleDetails", { 'methods': payload });
      setChats(chats);
    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [getChatDetail] api:[consoleDetails] get catchError", error);
    }
  }

  const getMailDetails = async () => {
    try {
      const payload = JSON.stringify(["mail"]);
      const { dashboardDeatils: { email: { data = {} } } } = await axiosGetCall("consoleDetails", { 'methods': payload });
      setMails(data);

    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [getMailDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const getDriveDetails = async () => {
    try {
      const payload = JSON.stringify(["drive"]);
      const { dashboardDeatils: { drive: { data = {} } } } = await axiosGetCall("consoleDetails", { 'methods': payload, type: documentType });
      setDriveData(data);
    } catch (error) {
      setLoader(false)
      logger.error("Dashboard fn: [getDriveDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const notifiAndAMDetails = async () => {
    try {
      const payload = JSON.stringify(["notification", "AM"])
      const { dashboardDeatils: { AM: { name = "", email = "", countryCode = "", mobile = "", profileUrl = "" }, notification: { count = 0, message = "" } } } = await axiosGetCall("consoleDetails", { 'methods': payload });

      setAMName(name);
      setEmail(email);
      setMobileNO(mobile);
      setCountryCode(countryCode);
      setCount(count);
      setMessage(message);
      setAMloader(false);
      setAMProfileUrl(profileUrl);
    } catch (error) {
      setAMloader(false)
      logger.error("Dashboard fn: [notifiAndAMDetails] api:[consoleDetails] get catchError", error);
    }
  }

  const adDetails = async () => {
    try {
      const { mobile, desktop, redirectPath } = await axiosGetCall("adDetails");
      setMobileAd(mobile);
      setDesktopAd(desktop);
      setAdPath(redirectPath);
    } catch (error) {
      logger.error("Dashboard fn: [adDetails] api: [adDetails] get catchError", error);
    }
  }

  const getChatFirstTime = async () => {
    try {
      await getChatDetail();
      chatLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [getChatFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  const fetchMeetingFirstTime = async () => {
    try {
      await meetDetails();
      meetLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [fetchMeetingFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  const fetchWebinarFirstTime = async () => {
    try {
      await webinarDetails();
      webinarLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [fetchWebinarFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  const fetchVoiceFirstTime = async () => {
    try {
      await getVoiceDetail();
      voiceLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [fetchVoiceFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  const fetchDriveDataFirstTime = async () => {
    try {
      await getDriveDetails();
      driveLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [fetchDriveDataFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  const fetchMailsFirstTime = async () => {
    try {
      await getMailDetails();
      mailLoader.loaderComplete();
    } catch (error) {
      logger.error("Dashboard fn: [fetchMailsFirstTime] api:[consoleDetails] get catchError", error);
    }
  }

  useEffect(() => {
    const token = decodeToken();
    if (token) {

      const data = token;
      const { om = "user", products = [] } = data;
      setJwtData({ om, products })
      setLoad(true)
    }
  }, [])
  console.log("in dashboard checking iframe", window.top !== window.self)
  useEffect(() => {

    if (load) {

      notifiAndAMDetails();

      const { products = [] } = jwtData;

      adDetails();
      products.includes("Meet") ? fetchMeetingFirstTime() : meetLoader.loaderComplete() || setLoader(false);
      products.includes("Webinar") ? fetchWebinarFirstTime() : webinarLoader.loaderComplete();
      products.includes("Broadcast") ? fetchVoiceFirstTime() : voiceLoader.loaderComplete() || setNotVoiceUser(true);
      products.includes("Chat") ? getChatFirstTime() : chatLoader.loaderComplete();
      products.includes("Drive") ? fetchDriveDataFirstTime() : driveLoader.loaderComplete();

      window.addEventListener('resize', () => setScreenHeight(window?.innerHeight));

      const intervalId = setInterval(() => {
        products.includes("Meet") && meetDetails();
        products.includes("Chat") && getChatDetail();
        products.includes("Broadcast") && getVoiceDetail();
        products.includes("Webinar") && webinarDetails();
      }, 5000);

      return () => {
        window.removeEventListener('resize', () => setScreenHeight(window?.innerHeight))
        clearInterval(intervalId)
      };
    }

    // eslint-disable-next-line
  }, [load])



  useEffect(() => {

    if (load) {

      if (jwtData?.om === "employee") {
        fetchMailsFirstTime();
        const intervalId = setInterval(() => {
          getMailDetails();
        }, 30000);

        return () => {
          window.removeEventListener('resize', () => setScreenHeight(window?.innerHeight))
          clearInterval(intervalId)
        };
      }
      else {
        mailLoader.loaderComplete();
      }

      window.addEventListener('resize', () => setScreenHeight(window?.innerHeight));

    }

    // eslint-disable-next-line
  }, [load])

  useEffect(() => {
    products.includes("Drive") && getDriveDetails();
    // eslint-disable-next-line
  }, [documentType, products])

  const [isSkipKyc, setSkipKyc] = useState(getLocalStorageItem("isSkipKyc"));


  return (
    <CardsMainSection sx={{ height: screenHeight }}>

      <SnackbarProvider>
        {!!(!isSkipKyc && count) && <KycSection>
          <KycSectionData setSkipKyc={setSkipKyc} />
        </KycSection>}
        <SectionOne>
          <MeetOpenRoomSection attendeeCount={attendeeCount} attendees={attendees} joinUrl={joinUrl} roomId={roomId} visibility={visibility} setVisibility={setVisibility} loader={loader} products={jwtData?.products || []} />

          <AccountManagerSection AMName={AMName} email={email} AMprofileUrl={AMprofileUrl} countryCode={countryCode} mobileNo={mobileNo} loader={AMloader} highlight={highlight} />
        </SectionOne>

        <MailNChatSection chats={chats} mails={mails} mailLoading={mailLoader.loading} mailProgress={mailLoader.progress} chatLoading={chatLoader.loading} chatProgress={chatLoader.progress} products={jwtData?.products || []} handleHighlight={handleHighlight} />
        <DriveSection data={driveData} driveLoading={driveLoader.loading} driveProgress={driveLoader.progress} products={jwtData?.products || []} handleHighlight={handleHighlight} documentType={documentType} setDocumentType={setDocumentType} />
        <MeetNWebinar meetData={meetDoc} meetLoading={meetLoader.loading} meetProgress={meetLoader.progress} webinarData={webinarDoc} webinarLoading={webinarLoader.loading} webinarProgress={webinarLoader.progress} voiceLoading={voiceLoader.loading} voiceProgress={voiceLoader.progress} callAnalyticsLabel={callAnalyticsLabel} callDetailsSeries={callDetailsSeries} runningCampaign={runningCampaign} runningShow={runningShow} notVoiceUser={notVoiceUser} products={jwtData?.products || []} handleHighlight={handleHighlight} />
        <SmsCampSection />

        {/* <SectionTwo>
        <UpcomingMeetSection title={title} startDate={startDate} endDate={endDate} loader={loader} roomId={sheduleMeetRoomId} />

        <PendingInvoiceOuterSection>

          <PendingInvoice />

          <SupportTicket />
        </PendingInvoiceOuterSection>


        <NotificationSection notificationCount={count} message={message} />
      </SectionTwo> */}

        {/* <SectionThree>
        <VoiceSection callAnalyticsLabel={callAnalyticsLabel} callDetailsSeries={callDetailsSeries} runningCampaign={runningCampaign} runningShow={runningShow} notVoiceUser={notVoiceUser} />

        <SmsAndVoiceSection>

          <SmsSection />

          <DeepCallSection />

        </SmsAndVoiceSection>


      </SectionThree> */}

        {/* <SectionFour>
        {desktopAd && <AdLeftSection mobileAd={mobileAd} desktopAd={desktopAd} redirectPath={adPath} />}

      </SectionFour> */}
      </SnackbarProvider>

    </CardsMainSection>
  )
}
